import { Form, Spin, Typography } from "antd/es";
import { connect } from "react-redux";
import {
  Button,
  Checkbox,
  Header,
  HorizontalStack,
  Icon,
  Image,
  KCol,
  KDatePicker,
  KDrawer,
  KRow,
  KTable,
  KTableColumn,
  KTableHeader,
  KTableRow,
  Label,
  NumberInput,
  TextArea,
  TextInput,
  VerticalStack
} from "../../components/web-components";
import { KSelectBox, Spacer } from "../../kuika";
import {
  hideImportMappedExcelModal,
  hideIyzicoDrawer,
  hidePhotoTake,
  hideStripeDrawer,
  showImportMappedExcelModal,
  showIyzicoDrawer,
  showPhotoTake,
  showStripeDrawer
} from "../../redux/modals/actions";
import { AppState } from "../../redux/root-reducer";
import { withContext } from "../../shared/hoc/with-context";
import { withForm } from "../../shared/hoc/with-form";
import { withGoogleLogin } from "../../shared/hoc/with-google-login";
import { withHistory } from "../../shared/hoc/with-history";
import { withScreenInput } from "../../shared/hoc/with-screen-input";
import { KuikaAppManager } from "../../shared/utilty/kuika-app-manager";
import { ReactSystemFunctions } from "../../shared/utilty/react-system-functions";
import { IsTakipKart_ScreenBase } from "./istakipkart-base";

import { AksiyonKart_Screen } from "../aksiyonkart/aksiyonkart";
import { BFormuBankaV2_Screen } from "../bformubankav2/bformubankav2";
import { BFormuTicari_Screen } from "../bformuticari/bformuticari";
import { BFormuTicariPrint_Screen } from "../bformuticariprint/bformuticariprint";
import { FiyatlandirmaOzelKart_Screen } from "../fiyatlandirmaozelkart/fiyatlandirmaozelkart";
import { LeftMenu_Screen } from "../leftmenu/leftmenu";
import { PopupMuzikBankasiSecimi_Screen } from "../popupmuzikbankasisecimi/popupmuzikbankasisecimi";

declare let window: any;
const { Title, Paragraph, Text, Link } = Typography;
const menuMap = [];

class IsTakipKart_Screen extends IsTakipKart_ScreenBase {
  render() {
    if (this.state.isPageVisible === false) {
      return <></>;
    }
    return (
      <>
        <Spin spinning={this.state.spinnerCount > 0}>
          <Form
            form={this.props.form}
            name="basic"
            initialValues={{
              istakipkart_889558_value: "283ac3c9-74b0-40c1-7273-08dbdfa0a8af",
              istakipkart_609729_value: this.state.getBasvuruFormuById?.at?.(0)?.formStatuID ?? undefined,
              istakipkart_352216_value: this.state.getBasvuruFormuById?.at?.(0)?.aksiyonIlgiliKisi ?? undefined,
              istakipkart_815119_value: undefined,
              istakipkart_846368_value: this.state.getBasvuruFormuById?.at?.(0)?.onemliNot ?? undefined,
              istakipkart_679331_value: this.state.getBasvuruFormuById?.at?.(0)?.eserAd ?? undefined,
              istakipkart_909777_value: this.state.getBasvuruFormuById?.at?.(0)?.eserSahibi ?? undefined,
              istakipkart_765584_value: this.state.getBasvuruFormuById?.at?.(0)?.basvuruTuruID ?? undefined,
              istakipkart_183673_value: this.state.getBasvuruFormuById?.at?.(0)?.projeTuruID ?? undefined,
              istakipkart_969221_value: this.state.getBasvuruFormuById?.at?.(0)?.olusturanKisi ?? undefined,
              istakipkart_284293_value: this.state.getBasvuruFormuById?.at?.(0)?.eMail ?? undefined,
              istakipkart_528021_value: this.state.getBasvuruFormuById?.at?.(0)?.gsm ?? undefined,
              istakipkart_473202_value: this.state.getBasvuruFormuById?.at?.(0)?.sirketAd ?? undefined,
              istakipkart_310363_value: this.state.getBasvuruFormuById?.at?.(0)?.aciklama ?? undefined,
              istakipkart_367546_value: this.state.getBasvuruFormuById?.at?.(0)?.acil ?? undefined,
              istakipkart_956828_value: this.state.getBasvuruFormuById?.at?.(0)?.terminTarihi ?? undefined,
              istakipkart_743413_value: this.state.getBasvuruFormuById?.at?.(0)?.servisUcretiVar ?? undefined,
              istakipkart_204534_value: this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined,
              istakipkart_681754_value: this.state.getBasvuruFormuById?.at?.(0)?.briefAciklama ?? undefined,
              istakipkart_449550_value: this.state.getBasvuruFormuById?.at?.(0)?.sozlesmeTarihi ?? undefined,
              istakipkart_218026_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansBasTarihi ?? undefined,
              istakipkart_345176_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansBitTarihi ?? undefined,
              istakipkart_787576_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansSuresiz ?? undefined,
              istakipkart_168257_value: this.state.getBasvuruFormuById?.at?.(0)?.cariID ?? undefined,
              istakipkart_494755_value: this.state.getBasvuruFormuById?.at?.(0)?.isIndirimliFiyat ?? undefined,
              istakipkart_771873_value: this.state.getBasvuruFormuById?.at?.(0)?.lisansTipiID ?? undefined,
              istakipkart_780759_value: this.state.getBasvuruFormuById?.at?.(0)?.telifTurID ?? undefined,
              istakipkart_953041_value: this.state.getBasvuruFormuById?.at?.(0)?.faturaTarihi ?? undefined,
              istakipkart_552514_value: this.state.getBasvuruFormuById?.at?.(0)?.faturaTutar ?? undefined,
              istakipkart_13037_value: undefined,
              istakipkart_748063_value: undefined,
              istakipkart_515308_value: undefined,
              istakipkart_77490_value: NaN,
              istakipkart_471403_value: NaN,
              istakipkart_462579_value: NaN,
              istakipkart_445836_value: "c0aa9184-625a-4539-c510-08da52e50202",
              istakipkart_684788_value: undefined,
              istakipkart_649647_value: this.state.getBasvuruFormuById?.at?.(0)?.dipNetTutar ?? undefined,
              istakipkart_952267_value: this.state.getBasvuruFormuById?.at?.(0)?.dipServisTutar ?? undefined,
              istakipkart_161516_value: this.state.getBasvuruFormuById?.at?.(0)?.stopajOran ?? undefined,
              istakipkart_830227_value: this.state.getBasvuruFormuById?.at?.(0)?.dipStopajTutar ?? undefined,
              istakipkart_20311_value: this.state.getBasvuruFormuById?.at?.(0)?.dipBrutTutar ?? undefined,
              istakipkart_368022_value: this.state.getBasvuruFormuById?.at?.(0)?.dipIndirim ?? undefined,
              istakipkart_241361_value: this.state.getBasvuruFormuById?.at?.(0)?.dipToplam ?? undefined,
              istakipkart_286867_value: this.state.getBasvuruFormuById?.at?.(0)?.dipKdv ?? undefined,
              istakipkart_228291_value: this.state.getBasvuruFormuById?.at?.(0)?.dipGenelToplam ?? undefined,
              istakipkart_353223_value: this.state.getBasvuruFormuById?.at?.(0)?.satirAciklama ?? undefined,
              istakipkart_116506_value: this.state.getBasvuruFormuById?.at?.(0)?.vadeGunID ?? undefined,
              istakipkart_624781_value: this.state.getBasvuruFormuById?.at?.(0)?.notSatir1 ?? undefined,
              istakipkart_608425_value: this.state.getBasvuruFormuById?.at?.(0)?.notSatir2 ?? undefined,
              istakipkart_93784_value: this.state.getBasvuruFormuById?.at?.(0)?.notSatir3 ?? undefined
            }}
            onValuesChange={(changedValues, allValues) => {
              this.clearFieldValidation(changedValues);
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
              <Header
                id="istakipkart_header"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundColor: "rgba(255, 255, 255, 1)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "left"
                  } as any
                }
              >
                <KRow
                  id="658116"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "contain",
                      backgroundPosition: "left",
                      paddingTop: 16,
                      paddingBottom: 16,
                      alignItems: "center",
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="572685"
                    xs={8}
                    sm={8}
                    md={8}
                    lg={8}
                    xl={8}
                    xxl={8}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        paddingRight: 16,
                        paddingLeft: 16,
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <HorizontalStack
                      id="145472"
                      direction="horizontal"
                      size={2}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          paddingTop: 0,
                          paddingRight: 0,
                          paddingBottom: 0,
                          paddingLeft: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <Icon
                        id="580322"
                        onClick={(e?: any) => {
                          if (e && e.stopPropagation) e.stopPropagation();
                          this.IsTakipKartComponent_580322_onClick();
                        }}
                        showCursorPointer
                        iconName="menu"
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "contain",
                            backgroundPosition: "left",
                            fontWeight: 500,
                            fontSize: "24px",
                            color: "rgba(172, 38, 42, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      ></Icon>

                      <Form.Item className="kFormItem" name="istakipkart_889558_value">
                        <Image
                          id="889558"
                          placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                          zoomOnClick={false}
                          imageFit="fit"
                          style={
                            {
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingLeft: 16,
                              height: "24px"
                            } as any
                          }
                        ></Image>
                      </Form.Item>
                    </HorizontalStack>
                  </KCol>

                  <KCol
                    id="55603"
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    xl={6}
                    xxl={6}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <Label
                      id="861144"
                      value={ReactSystemFunctions.translate(this.ml, 861144, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingLeft: 4,
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "rgba(183, 14, 14, 1)"
                        } as any
                      }
                    ></Label>

                    <Label
                      id="785916"
                      value={this.state.getBasvuruFormuById?.at?.(0)?.ticketNo ?? undefined}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingRight: 4,
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "rgba(183, 14, 14, 1)"
                        } as any
                      }
                    ></Label>

                    <Label
                      id="656565"
                      value={ReactSystemFunctions.translate(this.ml, 656565, "value", this.defaultML)}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingLeft: 4,
                          fontFamily: "Poppins",
                          fontWeight: 600,
                          fontSize: "18px",
                          color: "rgba(183, 14, 14, 1)"
                        } as any
                      }
                    ></Label>
                  </KCol>

                  <KCol
                    id="597215"
                    xs={10}
                    sm={10}
                    md={10}
                    lg={10}
                    xl={10}
                    xxl={10}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        alignItems: "center",
                        textAlign: "-webkit-center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KRow
                      id="261112"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          fontFamily: "Poppins",
                          fontWeight: 500,
                          fontSize: "14px",
                          color: "rgba(68, 68, 68, 1)",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="225712"
                        xs={13}
                        sm={13}
                        md={13}
                        lg={13}
                        xl={13}
                        xxl={13}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-right",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Icon
                          id="927915"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.IsTakipKartComponent_927915_onClick();
                          }}
                          showCursorPointer
                          iconName="arrow_forward"
                          style={
                            {
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                              borderBottomLeftRadius: 8,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(211, 211, 211, 1)",
                              borderStyle: "solid",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              fontWeight: 500,
                              fontSize: "24px",
                              color: "rgba(183, 14, 14, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Icon>

                        <Label
                          id="47131"
                          value={ReactSystemFunctions.translate(this.ml, 47131, "value", this.defaultML)}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingRight: 8,
                              paddingLeft: 8,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(172, 38, 42, 1)"
                            } as any
                          }
                        ></Label>

                        <Icon
                          id="817677"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.IsTakipKartComponent_817677_onClick();
                          }}
                          showCursorPointer
                          iconName="arrow_back"
                          style={
                            {
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                              borderBottomLeftRadius: 8,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(211, 211, 211, 1)",
                              borderStyle: "solid",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              fontWeight: 500,
                              fontSize: "24px",
                              color: "rgba(183, 14, 14, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Icon>
                      </KCol>

                      <KCol
                        id="443486"
                        xs={3}
                        sm={3}
                        md={3}
                        lg={3}
                        xl={3}
                        xxl={3}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            alignItems: "center",
                            textAlign: "-webkit-center",
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Icon
                          id="553628"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.IsTakipKartComponent_553628_onClick();
                          }}
                          showCursorPointer
                          visibility={this.state.isComp553628Visible}
                          iconName="assignment_return"
                          style={
                            {
                              borderTopLeftRadius: 8,
                              borderTopRightRadius: 8,
                              borderBottomRightRadius: 8,
                              borderBottomLeftRadius: 8,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(211, 211, 211, 1)",
                              borderStyle: "solid",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              fontWeight: 500,
                              fontSize: "24px",
                              color: "rgba(183, 14, 14, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Icon>
                      </KCol>

                      <KCol
                        id="809014"
                        xs={8}
                        sm={8}
                        md={8}
                        lg={8}
                        xl={8}
                        xxl={8}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            fontFamily: "Poppins",
                            fontWeight: 500,
                            fontSize: "14px",
                            color: "rgba(68, 68, 68, 1)",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <Button
                          id="418150"
                          onClick={(e?: any) => {
                            if (e && e.stopPropagation) e.stopPropagation();
                            this.IsTakipKartComponent_418150_onClick();
                          }}
                          showCursorPointer
                          label={ReactSystemFunctions.translate(this.ml, 418150, "label", this.defaultML)}
                          size="middle"
                          loading={false}
                          ghost={false}
                          block={false}
                          htmlType="button"
                          iconPosition="left"
                          danger={false}
                          style={
                            {
                              borderTopLeftRadius: 32,
                              borderTopRightRadius: 32,
                              borderBottomRightRadius: 32,
                              borderBottomLeftRadius: 32,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderColor: "rgba(172, 38, 42, 1)",
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 0.5)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              display: "inline",
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "12px",
                              color: "rgba(172, 38, 42, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        ></Button>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>
              </Header>

              <div
                id="istakipkart_body"
                style={
                  {
                    borderStyle: "solid",
                    borderTopWidth: 0,
                    borderRightWidth: 0,
                    borderBottomWidth: 0,
                    borderLeftWidth: 0,
                    backgroundImage:
                      "url(" +
                      KuikaAppManager.GetBackendUrl() +
                      "/resource/runtime/byid?resourceId=31886a70-856c-4afc-8e82-08da211d182d)",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    paddingTop: 24,
                    paddingRight: 16,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    display: "block"
                  } as any
                }
              >
                <KRow
                  id="109624"
                  align="top"
                  justify="start"
                  horizontalGutter={0}
                  verticalGutter={0}
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 16,
                      paddingBottom: 16,
                      fontWeight: 500,
                      fontSize: "14px",
                      letterSpacing: "0.5px"
                    } as any
                  }
                >
                  <KCol
                    id="236893"
                    xs={24}
                    sm={24}
                    md={24}
                    lg={24}
                    xl={24}
                    xxl={24}
                    order={0}
                    pull={0}
                    push={0}
                    style={
                      {
                        borderStyle: "solid",
                        borderTopWidth: 0,
                        borderRightWidth: 0,
                        borderBottomWidth: 0,
                        borderLeftWidth: 0,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "contain",
                        backgroundPosition: "left",
                        paddingRight: 16,
                        paddingLeft: 16,
                        textAlign: "-webkit-center",
                        fontWeight: 500,
                        fontSize: "14px",
                        letterSpacing: "0.5px"
                      } as any
                    }
                  >
                    <KRow
                      id="405304"
                      align="top"
                      justify="start"
                      horizontalGutter={0}
                      verticalGutter={0}
                      style={
                        {
                          borderTopLeftRadius: 12,
                          borderTopRightRadius: 12,
                          borderBottomRightRadius: 12,
                          borderBottomLeftRadius: 12,
                          borderStyle: "solid",
                          borderTopWidth: 0,
                          borderRightWidth: 0,
                          borderBottomWidth: 0,
                          borderLeftWidth: 0,
                          backgroundColor: "rgba(255, 255, 255, 1)",
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "contain",
                          backgroundPosition: "left",
                          paddingTop: 16,
                          paddingBottom: 16,
                          maxWidth: "1600px",
                          fontWeight: 500,
                          fontSize: "14px",
                          letterSpacing: "0.5px"
                        } as any
                      }
                    >
                      <KCol
                        id="868977"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={24}
                        xl={24}
                        xxl={24}
                        order={0}
                        pull={0}
                        push={0}
                        style={
                          {
                            borderStyle: "solid",
                            borderTopWidth: 0,
                            borderRightWidth: 0,
                            borderBottomWidth: 0,
                            borderLeftWidth: 0,
                            paddingRight: 16,
                            paddingLeft: 16,
                            fontWeight: 500,
                            fontSize: "14px",
                            letterSpacing: "0.5px"
                          } as any
                        }
                      >
                        <KRow
                          id="940423"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 8,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="312414"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderRightWidth: 1,
                                borderColor: "rgba(155, 155, 155, 1)",
                                borderStyle: "dotted",
                                borderTopWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                paddingRight: 8,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="301292"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopLeftRadius: 16,
                                  borderTopRightRadius: 16,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(243, 241, 241, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 4,
                                  paddingRight: 16,
                                  paddingBottom: 4,
                                  paddingLeft: 16,
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="544983"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <HorizontalStack
                                  id="209033"
                                  direction="horizontal"
                                  size={12}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <Button
                                    id="91798"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.IsTakipKartComponent_91798_onClick();
                                    }}
                                    showCursorPointer
                                    visibility={this.state.isComp91798Visible}
                                    label={ReactSystemFunctions.translate(this.ml, 91798, "label", this.defaultML)}
                                    size="middle"
                                    loading={false}
                                    ghost={false}
                                    block={false}
                                    htmlType="button"
                                    iconPosition="left"
                                    danger={false}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 4,
                                        paddingBottom: 0,
                                        paddingLeft: 4,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Button>

                                  <Button
                                    id="513972"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.IsTakipKartComponent_513972_onClick();
                                    }}
                                    showCursorPointer
                                    visibility={this.state.isComp513972Visible}
                                    label={ReactSystemFunctions.translate(this.ml, 513972, "label", this.defaultML)}
                                    size="middle"
                                    loading={false}
                                    ghost={false}
                                    block={false}
                                    htmlType="button"
                                    iconPosition="left"
                                    danger={false}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 4,
                                        paddingBottom: 0,
                                        paddingLeft: 4,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Button>

                                  <Button
                                    id="659451"
                                    onClick={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.IsTakipKartComponent_659451_onClick();
                                    }}
                                    showCursorPointer
                                    visibility={this.state.isComp659451Visible}
                                    label={ReactSystemFunctions.translate(this.ml, 659451, "label", this.defaultML)}
                                    size="middle"
                                    loading={false}
                                    ghost={false}
                                    block={false}
                                    htmlType="button"
                                    iconPosition="left"
                                    danger={false}
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 0,
                                        paddingRight: 4,
                                        paddingBottom: 0,
                                        paddingLeft: 4,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></Button>
                                </HorizontalStack>
                              </KCol>
                            </KRow>

                            <KRow
                              id="920012"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(243, 241, 241, 1)",
                                  paddingTop: 0,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="373542"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="357085"
                                  value={ReactSystemFunctions.translate(this.ml, 357085, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="308766"
                                  value={this.state.getBasvuruFormuById?.at?.(0)?.olusturmaTarihi ?? undefined}
                                  formatter="date"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingLeft: 8,
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontSize: "12px",
                                      color: "rgba(0, 0, 0, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="883717"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="121807"
                                  value={ReactSystemFunctions.translate(this.ml, 121807, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="354958"
                                  value={this.state.getBasvuruFormuById?.at?.(0)?.isTakipBaslangicTarihi ?? undefined}
                                  formatter="date"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      textAlign: "-webkit-left",
                                      display: "block",
                                      fontSize: "12px",
                                      color: "rgba(0, 0, 0, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="187442"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="809164"
                                  value={ReactSystemFunctions.translate(this.ml, 809164, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="72949"
                                  value={this.state.getBasvuruFormuById?.at?.(0)?.isTakipGuncellemeZaman ?? undefined}
                                  formatter="date"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingLeft: 8,
                                      textAlign: "-webkit-center",
                                      fontSize: "12px",
                                      color: "rgba(0, 0, 0, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="107150"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="462797"
                                  value={ReactSystemFunctions.translate(this.ml, 462797, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="174064"
                                  value={this.state.getBasvuruFormuById?.at?.(0)?.terminTarihi ?? undefined}
                                  formatter="date"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingLeft: 8,
                                      display: "block",
                                      fontSize: "12px",
                                      color: "rgba(0, 0, 0, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>

                            <KRow
                              id="242767"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(246, 243, 243, 1)",
                                  paddingTop: 16,
                                  paddingRight: 16,
                                  paddingBottom: 16,
                                  paddingLeft: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="435545"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="823422"
                                  value={ReactSystemFunctions.translate(this.ml, 823422, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <KRow
                                  id="802775"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="127237"
                                    xs={22}
                                    sm={22}
                                    md={22}
                                    lg={22}
                                    xl={22}
                                    xxl={22}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_609729_value">
                                      <KSelectBox
                                        id="609729"
                                        kuikaRef={this.istakipkart_609729_value_kuikaSelectBoxRef}
                                        options={this.state.sbFormStatuList}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          609729,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={false}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="id"
                                        datatextfield="tanim"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="470294"
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    xxl={2}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingRight: 3,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="382094"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IsTakipKartComponent_382094_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp382094Visible}
                                      iconName="autorenew"
                                      hoverText="Durum Güncelle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="744116"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderBottomRightRadius: 16,
                                  borderBottomLeftRadius: 16,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(246, 245, 243, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingRight: 16,
                                  paddingBottom: 16,
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="81800"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="481416"
                                  value={ReactSystemFunctions.translate(this.ml, 481416, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <KRow
                                  id="443721"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="173646"
                                    xs={21}
                                    sm={21}
                                    md={21}
                                    lg={21}
                                    xl={21}
                                    xxl={21}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_352216_value">
                                      <KSelectBox
                                        id="352216"
                                        editability={this.state.isComp352216Enabled}
                                        kuikaRef={this.istakipkart_352216_value_kuikaSelectBoxRef}
                                        options={this.state.KUserList}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          352216,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={false}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="userName"
                                        datatextfield="userName"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="629453"
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    xxl={3}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="57039"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IsTakipKartComponent_57039_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp57039Visible}
                                      iconName="autorenew"
                                      hoverText="Kişi Güncelle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="121642"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingLeft: 4,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="693391"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="818338"
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="204969"
                                      value={ReactSystemFunctions.translate(this.ml, 204969, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="224802"
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="72181"
                                    xs={8}
                                    sm={8}
                                    md={8}
                                    lg={8}
                                    xl={8}
                                    xxl={8}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingRight: 3,
                                        alignItems: "center",
                                        textAlign: "-webkit-right"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="927155"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IsTakipKartComponent_927155_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp927155Visible}
                                      iconName="add_circle"
                                      hoverText="Ekle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="18544"
                                  visibility={this.state.isComp18544Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(252, 250, 231, 1)",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="946746"
                                    xs={18}
                                    sm={18}
                                    md={18}
                                    lg={18}
                                    xl={18}
                                    xxl={18}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_815119_value">
                                      <KSelectBox
                                        id="815119"
                                        kuikaRef={this.istakipkart_815119_value_kuikaSelectBoxRef}
                                        options={this.state.KUserList}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          815119,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={true}
                                        autoClearSearchValue={true}
                                        showSearch={true}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="userName"
                                        datatextfield="userName"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(252, 250, 231, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="288317"
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    xxl={3}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="994969"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IsTakipKartComponent_994969_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="add_task"
                                      hoverText="Ekle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-right",
                                          color: "rgba(65, 117, 5, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>

                                  <KCol
                                    id="774596"
                                    xs={3}
                                    sm={3}
                                    md={3}
                                    lg={3}
                                    xl={3}
                                    xxl={3}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="461079"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IsTakipKartComponent_461079_onClick();
                                      }}
                                      showCursorPointer
                                      iconName="highlight_off"
                                      hoverText="Kapat"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          textAlign: "-webkit-right",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KTable
                                  id="805288"
                                  editability={this.state.isComp805288Enabled}
                                  kuikaRef={this.istakipkart_805288_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getListIsTakipCCbyBFID}
                                  size="middle"
                                  bordered={true}
                                  showHeader={false}
                                  loading={false}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    805288,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  sorter={false}
                                  pagination={false}
                                  hideTableOnEmpty={true}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  searchable={false}
                                  filtering={false}
                                  transformedOnMobileResolution={false}
                                  tableWidthMode="fit"
                                  columnChooser={false}
                                  fixedHeader={false}
                                  editable={false}
                                  globalSearch={false}
                                  pageSize={10}
                                  showSizeChanger={false}
                                  resizableColumns={false}
                                  insertRowPosition="top"
                                  showNoDataFound={true}
                                  multiSelect={false}
                                  export={false}
                                  editableAlwaysActive={false}
                                  hideHeaderOnMobileResolution={false}
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(218, 218, 218, 1)",
                                      borderStyle: "solid",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="786934"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="529403"
                                    hoverFontColor="red"
                                    hoverBgColor="#F5F5F5"
                                    style={
                                      {
                                        borderTopWidth: 1,
                                        borderRightWidth: 1,
                                        borderBottomWidth: 1,
                                        borderLeftWidth: 1,
                                        borderColor: "rgba(218, 218, 218, 1)",
                                        borderStyle: "solid",
                                        paddingTop: 0,
                                        paddingRight: 0,
                                        paddingBottom: 0,
                                        paddingLeft: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="140494"
                                      title={ReactSystemFunctions.translate(this.ml, 140494, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderTopWidth: 1,
                                          borderRightWidth: 1,
                                          borderBottomWidth: 1,
                                          borderLeftWidth: 1,
                                          borderColor: "rgba(218, 218, 218, 1)",
                                          borderStyle: "solid",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KRow
                                        id="472826"
                                        align="top"
                                        justify="start"
                                        horizontalGutter={0}
                                        verticalGutter={0}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            height: "32px",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KCol
                                          id="188360"
                                          xs={22}
                                          sm={22}
                                          md={22}
                                          lg={22}
                                          xl={22}
                                          xxl={22}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingLeft: 6,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="946132"
                                            value="[datafield:ilgiliKisi]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          ></Label>
                                        </KCol>

                                        <KCol
                                          id="265874"
                                          xs={2}
                                          sm={2}
                                          md={2}
                                          lg={2}
                                          xl={2}
                                          xxl={2}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-center",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Icon
                                            id="261717"
                                            onClick={(e?: any) => {
                                              if (e && e.stopPropagation) e.stopPropagation();
                                              this.IsTakipKartComponent_261717_onClick();
                                            }}
                                            showCursorPointer
                                            iconName="remove_circle_outline"
                                            hoverText="Sil"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                alignItems: "center",
                                                textAlign: "-webkit-center",
                                                color: "rgba(172, 38, 42, 1)"
                                              } as any
                                            }
                                          ></Icon>
                                        </KCol>
                                      </KRow>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>

                            <KRow
                              id="999531"
                              visibility={this.state.isComp999531Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="610994"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="987639"
                                  value={ReactSystemFunctions.translate(this.ml, 987639, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="istakipkart_846368_value">
                                  <TextArea
                                    id="846368"
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      846368,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoSize={false}
                                    showCount={false}
                                    formatter="None"
                                    readOnly={false}
                                    style={
                                      {
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></TextArea>
                                </Form.Item>
                              </KCol>
                            </KRow>

                            <KRow
                              id="417866"
                              visibility={this.state.isComp417866Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 32,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="411478"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Button
                                  id="324369"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IsTakipKartComponent_324369_onClick();
                                  }}
                                  showCursorPointer
                                  visibility={this.state.isComp324369Visible}
                                  label={ReactSystemFunctions.translate(this.ml, 324369, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 32,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 32,
                                      borderBottomLeftRadius: 32,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(172, 38, 42, 1)",
                                      borderStyle: "solid",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Button>
                              </KCol>

                              <KCol
                                id="73495"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="436047"
                                xs={8}
                                sm={8}
                                md={8}
                                lg={8}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Button
                                  id="140302"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IsTakipKartComponent_140302_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 140302, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="right"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 32,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 32,
                                      borderBottomLeftRadius: 32,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(172, 38, 42, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(172, 38, 42, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(255, 255, 255, 1)"
                                    } as any
                                  }
                                ></Button>
                              </KCol>
                            </KRow>
                          </KCol>

                          <KCol
                            id="831397"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="544562"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="589214"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={20}
                                xxl={20}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="536756"
                                  value={ReactSystemFunctions.translate(this.ml, 536756, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="990848"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    alignItems: "center",
                                    textAlign: "-webkit-right",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="363488"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IsTakipKartComponent_363488_onClick();
                                  }}
                                  showCursorPointer
                                  visibility={this.state.isComp363488Visible}
                                  iconName="add_circle"
                                  hoverText="Yeni Not Ekle"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontSize: "24px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>

                            <KRow
                              id="110040"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="167216"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTable
                                  id="729731"
                                  kuikaRef={this.istakipkart_729731_value_kuikaTableRef}
                                  form={this.props.form}
                                  dataSource={this.state.getListBFormuAksiyon}
                                  size="middle"
                                  bordered={true}
                                  loading={false}
                                  transformedOnMobileResolution={false}
                                  hideHeaderOnMobileResolution={false}
                                  showHeader={true}
                                  fixedHeader={false}
                                  editable={false}
                                  tableWidthMode="fit"
                                  globalSearch={false}
                                  searchable={false}
                                  sorter={false}
                                  filtering={false}
                                  pagination={false}
                                  pageSize={10}
                                  showSizeChanger={false}
                                  columnChooser={false}
                                  resizableColumns={false}
                                  hideTableOnEmpty={true}
                                  striped={false}
                                  stripedColor="#F5F7FA"
                                  insertRowActive={false}
                                  insertRowPosition="top"
                                  showNoDataFound={true}
                                  nodatafoundmessage={ReactSystemFunctions.translate(
                                    this.ml,
                                    729731,
                                    "nodatafoundmessage",
                                    this.defaultML
                                  )}
                                  multiSelect={false}
                                  export={false}
                                  editableAlwaysActive={false}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(155, 155, 155, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KTableHeader
                                    id="224391"
                                    hideOnMobileResolution={false}
                                    textDirection="Default"
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KTableHeader>

                                  <KTableRow
                                    id="942439"
                                    hoverFontColor="red"
                                    hoverBgColor="yellow"
                                    style={
                                      {
                                        borderTopLeftRadius: 0,
                                        borderTopRightRadius: 0,
                                        borderBottomRightRadius: 0,
                                        borderBottomLeftRadius: 0,
                                        borderTopWidth: 1,
                                        borderColor: "rgba(204, 204, 204, 1)",
                                        borderStyle: "solid",
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KTableColumn
                                      id="554538"
                                      title={ReactSystemFunctions.translate(this.ml, 554538, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="432518"
                                        value="[datafield:aksiyonTarihi]"
                                        formatter="date"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="476479"
                                      title={ReactSystemFunctions.translate(this.ml, 476479, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="501187"
                                        value="[datafield:nick]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="692404"
                                      title={ReactSystemFunctions.translate(this.ml, 692404, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="74606"
                                        value="[datafield:aksiyonTanim]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="153446"
                                      title={ReactSystemFunctions.translate(this.ml, 153446, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 8,
                                          paddingBottom: 8,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="873477"
                                        value="[datafield:aciklama]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KTableColumn>

                                    <KTableColumn
                                      id="747031"
                                      title={ReactSystemFunctions.translate(this.ml, 747031, "title", this.defaultML)}
                                      alwaysVisibleOnMobileResolution={false}
                                      hideFiltering={false}
                                      hideSorting={false}
                                      hideSearch={false}
                                      footerOptions="none"
                                      footerFontSize={14}
                                      footerFontColor="rgb(68, 68, 68)"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Spacer id="564220" direction="horizontal" size={12} style={{} as any}>
                                        <Icon
                                          id="432446"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.IsTakipKartComponent_432446_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp432446Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.getBasvuruFormuById[0]?.formReadOnly?.toString(),
                                              "0"
                                            )
                                          }
                                          iconName="delete"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontSize: "24px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="934923"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.IsTakipKartComponent_934923_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp934923Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.getBasvuruFormuById[0]?.formReadOnly?.toString(),
                                              "0"
                                            )
                                          }
                                          iconName="create"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontSize: "24px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>
                                      </Spacer>
                                    </KTableColumn>
                                  </KTableRow>
                                </KTable>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="842070"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingBottom: 8,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="60016"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="632444"
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="591012"
                              visibility={this.state.isComp591012Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                  borderBottomRightRadius: 32,
                                  borderBottomLeftRadius: 32,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderColor: "rgba(208, 2, 27, 1)",
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingRight: 32,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="996265"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    textAlign: "-webkit-right",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Icon
                                  id="685284"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IsTakipKartComponent_685284_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="arrow_forward"
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(211, 211, 211, 1)",
                                      borderStyle: "solid",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "24px",
                                      color: "rgba(183, 14, 14, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Icon>

                                <Label
                                  id="591608"
                                  value={ReactSystemFunctions.translate(this.ml, 591608, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingRight: 8,
                                      paddingLeft: 8,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Icon
                                  id="451453"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IsTakipKartComponent_451453_onClick();
                                  }}
                                  showCursorPointer
                                  iconName="arrow_back"
                                  style={
                                    {
                                      borderTopLeftRadius: 8,
                                      borderTopRightRadius: 8,
                                      borderBottomRightRadius: 8,
                                      borderBottomLeftRadius: 8,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(211, 211, 211, 1)",
                                      borderStyle: "solid",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "24px",
                                      color: "rgba(183, 14, 14, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Icon>
                              </KCol>
                            </KRow>
                          </KCol>

                          <KCol
                            id="884758"
                            xs={6}
                            sm={6}
                            md={6}
                            lg={6}
                            xl={6}
                            xxl={6}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                alignItems: "center",
                                textAlign: "-webkit-right",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="572328"
                              visibility={this.state.isComp572328Visible}
                              value={ReactSystemFunctions.translate(this.ml, 572328, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingRight: 8,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Label
                              id="803842"
                              visibility={this.state.isComp803842Visible}
                              value={ReactSystemFunctions.translate(this.ml, 803842, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingRight: 8,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Icon
                              id="803993"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.IsTakipKartComponent_803993_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp803993Visible}
                              iconName="arrow_circle_down"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingRight: 0,
                                  fontSize: "24pt",
                                  color: "rgba(183, 14, 14, 1)"
                                } as any
                              }
                            ></Icon>

                            <Icon
                              id="816615"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.IsTakipKartComponent_816615_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp816615Visible}
                              iconName="arrow_circle_up"
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingRight: 0,
                                  fontSize: "24pt",
                                  color: "rgba(183, 14, 14, 1)"
                                } as any
                              }
                            ></Icon>
                          </KCol>
                        </KRow>

                        <KRow
                          id="545617"
                          visibility={this.state.isComp545617Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="877318"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="375212"
                              visibility={this.state.isComp375212Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopLeftRadius: 16,
                                  borderTopRightRadius: 16,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(252, 211, 145, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingRight: 16,
                                  paddingBottom: 16,
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="976062"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="805329"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="52201"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="594005"
                                      value={ReactSystemFunctions.translate(this.ml, 594005, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="795410"
                                    xs={15}
                                    sm={15}
                                    md={15}
                                    lg={15}
                                    xl={15}
                                    xxl={15}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="679771"
                                      value={this.state.getBasvuruFormuById?.at?.(0)?.eserAd ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_679331_value">
                                      <TextInput
                                        id="679331"
                                        visibility={this.state.isComp679331Visible}
                                        editability={this.state.isComp679331Enabled}
                                        kuikaRef={this.istakipkart_679331_value_kuikaTextInputRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          679331,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        bordered={true}
                                        disabled={false}
                                        type="text"
                                        iconColor="rgba(0, 0, 0, 1)"
                                        formatter="None"
                                        isCharOnly={false}
                                        autoComplete={true}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            height: "32px",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></TextInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="867325"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="399601"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="612558"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="713140"
                                      value={ReactSystemFunctions.translate(this.ml, 713140, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="376501"
                                    xs={15}
                                    sm={15}
                                    md={15}
                                    lg={15}
                                    xl={15}
                                    xxl={15}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="872419"
                                      value={this.state.getBasvuruFormuById?.at?.(0)?.eserSahibi ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_909777_value">
                                      <TextInput
                                        id="909777"
                                        visibility={this.state.isComp909777Visible}
                                        editability={this.state.isComp909777Enabled}
                                        kuikaRef={this.istakipkart_909777_value_kuikaTextInputRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          909777,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        bordered={true}
                                        disabled={false}
                                        type="text"
                                        iconColor="rgba(0, 0, 0, 1)"
                                        formatter="None"
                                        isCharOnly={false}
                                        autoComplete={true}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            height: "32px",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></TextInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="608275"
                              visibility={this.state.isComp608275Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(184, 233, 134, 0.74)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingRight: 16,
                                  paddingBottom: 16,
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="592062"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingRight: 16,
                                    paddingLeft: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="444902"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="200434"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="298171"
                                      value={ReactSystemFunctions.translate(this.ml, 298171, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="473573"
                                    xs={15}
                                    sm={15}
                                    md={15}
                                    lg={15}
                                    xl={15}
                                    xxl={15}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="880403"
                                      value={this.state.getBasvuruFormuById?.at?.(0)?.basvuruTuruTanim ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_765584_value">
                                      <KSelectBox
                                        id="765584"
                                        visibility={this.state.isComp765584Visible}
                                        editability={this.state.isComp765584Enabled}
                                        kuikaRef={this.istakipkart_765584_value_kuikaSelectBoxRef}
                                        options={this.state.sbBasvuruTuruList}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          765584,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={false}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="id"
                                        datatextfield="tanim"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="511303"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="624426"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="252441"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="445583"
                                      value={ReactSystemFunctions.translate(this.ml, 445583, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="115987"
                                    xs={15}
                                    sm={15}
                                    md={15}
                                    lg={15}
                                    xl={15}
                                    xxl={15}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="334144"
                                      value={this.state.getBasvuruFormuById?.at?.(0)?.projeTuru ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_183673_value">
                                      <KSelectBox
                                        id="183673"
                                        visibility={this.state.isComp183673Visible}
                                        editability={this.state.isComp183673Enabled}
                                        kuikaRef={this.istakipkart_183673_value_kuikaSelectBoxRef}
                                        options={this.state.spGetProjeTuruByBTuruKod}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          183673,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={false}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="id"
                                        datatextfield="tanim"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="391583"
                              visibility={this.state.isComp391583Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="98864"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(184, 233, 134, 0.44)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="940885"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="954584"
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="646665"
                                      value={ReactSystemFunctions.translate(this.ml, 646665, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="46198"
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        textAlign: "-webkit-right",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KCol>
                                </KRow>

                                <KRow
                                  id="473686"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingRight: 16,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="378147"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="659206"
                                      value={ReactSystemFunctions.translate(this.ml, 659206, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="459883"
                                    xs={15}
                                    sm={15}
                                    md={15}
                                    lg={15}
                                    xl={15}
                                    xxl={15}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="707596"
                                      value={this.state.getBasvuruFormuById?.at?.(0)?.adSoyad ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_969221_value">
                                      <KSelectBox
                                        id="969221"
                                        visibility={this.state.isComp969221Visible}
                                        kuikaRef={this.istakipkart_969221_value_kuikaSelectBoxRef}
                                        options={this.state.KUserList}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          969221,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={false}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="userName"
                                        datatextfield="firstLastName"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="480955"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingRight: 16,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="844183"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="962183"
                                      value={ReactSystemFunctions.translate(this.ml, 962183, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="383707"
                                    xs={15}
                                    sm={15}
                                    md={15}
                                    lg={15}
                                    xl={15}
                                    xxl={15}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="96402"
                                      value={this.state.getBasvuruFormuById?.at?.(0)?.eMail ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_284293_value">
                                      <TextInput
                                        id="284293"
                                        visibility={this.state.isComp284293Visible}
                                        kuikaRef={this.istakipkart_284293_value_kuikaTextInputRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          284293,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        bordered={true}
                                        disabled={false}
                                        type="text"
                                        iconColor="rgba(0, 0, 0, 1)"
                                        formatter="None"
                                        isCharOnly={false}
                                        autoComplete={true}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></TextInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="261169"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingRight: 16,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="618613"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="708692"
                                      value={ReactSystemFunctions.translate(this.ml, 708692, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="265799"
                                    xs={15}
                                    sm={15}
                                    md={15}
                                    lg={15}
                                    xl={15}
                                    xxl={15}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="610845"
                                      value={this.state.getBasvuruFormuById?.at?.(0)?.gsm ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_528021_value">
                                      <TextInput
                                        id="528021"
                                        visibility={this.state.isComp528021Visible}
                                        kuikaRef={this.istakipkart_528021_value_kuikaTextInputRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          528021,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        bordered={true}
                                        disabled={false}
                                        type="text"
                                        iconColor="rgba(0, 0, 0, 1)"
                                        formatter="None"
                                        isCharOnly={false}
                                        autoComplete={true}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></TextInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="291667"
                                  visibility={this.state.isComp291667Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingRight: 16,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="523918"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="888592"
                                      value={ReactSystemFunctions.translate(this.ml, 888592, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="81042"
                                    xs={15}
                                    sm={15}
                                    md={15}
                                    lg={15}
                                    xl={15}
                                    xxl={15}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_473202_value">
                                      <TextInput
                                        id="473202"
                                        kuikaRef={this.istakipkart_473202_value_kuikaTextInputRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          473202,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        bordered={true}
                                        disabled={false}
                                        type="text"
                                        iconColor="rgba(0, 0, 0, 1)"
                                        formatter="None"
                                        isCharOnly={false}
                                        autoComplete={true}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></TextInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="46954"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingRight: 16,
                                      paddingBottom: 0,
                                      paddingLeft: 16,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="69339"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="157741"
                                      value={ReactSystemFunctions.translate(this.ml, 157741, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="598572"
                                    xs={15}
                                    sm={15}
                                    md={15}
                                    lg={15}
                                    xl={15}
                                    xxl={15}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="865525"
                                      value={this.state.getBasvuruFormuById?.at?.(0)?.aciklama ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_310363_value">
                                      <TextArea
                                        id="310363"
                                        visibility={this.state.isComp310363Visible}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          310363,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoSize={false}
                                        showCount={false}
                                        formatter="None"
                                        readOnly={false}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></TextArea>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="957274"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="77432"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="481165"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          alignItems: "flex-start",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="422890"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="225806"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            225806,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="587674"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="500409"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 16,
                                              paddingBottom: 16,
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="223730"
                                            xs={8}
                                            sm={8}
                                            md={8}
                                            lg={8}
                                            xl={8}
                                            xxl={8}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                alignItems: "center",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="istakipkart_367546_value">
                                              <Checkbox
                                                id="367546"
                                                editability={this.state.isComp367546Enabled}
                                                children={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  367546,
                                                  "children",
                                                  this.defaultML
                                                )}
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "contain",
                                                    backgroundPosition: "left",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    letterSpacing: "0.5px"
                                                  } as any
                                                }
                                              ></Checkbox>
                                            </Form.Item>
                                          </KCol>

                                          <KCol
                                            id="915843"
                                            xs={16}
                                            sm={16}
                                            md={16}
                                            lg={16}
                                            xl={16}
                                            xxl={16}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="490476"
                                              value={this.state.getBasvuruFormuById?.at?.(0)?.terminTarihi ?? undefined}
                                              formatter="date"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingTop: 0,
                                                  paddingRight: 0,
                                                  paddingBottom: 0,
                                                  paddingLeft: 0,
                                                  alignItems: "center",
                                                  textAlign: "-webkit-left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  letterSpacing: "0.5px"
                                                } as any
                                              }
                                            ></Label>

                                            <Form.Item className="kFormItem" name="istakipkart_956828_value" noStyle>
                                              <KDatePicker
                                                id="956828"
                                                visibility={this.state.isComp956828Visible}
                                                placeholder={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  956828,
                                                  "placeholder",
                                                  this.defaultML
                                                )}
                                                allowClear={true}
                                                format="DD/MM/YYYY"
                                                use12Hours={false}
                                                style={
                                                  {
                                                    borderBottomWidth: 1,
                                                    borderColor: "rgba(155, 155, 155, 1)",
                                                    borderStyle: "dotted",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "contain",
                                                    backgroundPosition: "left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "12px",
                                                    color: "rgba(47, 47, 47, 1)"
                                                  } as any
                                                }
                                              ></KDatePicker>
                                            </Form.Item>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="756009"
                                      visibility={this.state.isComp756009Visible}
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 16,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="884494"
                                        xs={24}
                                        sm={24}
                                        md={24}
                                        lg={24}
                                        xl={24}
                                        xxl={24}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            alignItems: "center",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KRow
                                          id="225141"
                                          align="top"
                                          justify="start"
                                          horizontalGutter={0}
                                          verticalGutter={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingRight: 16,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <KCol
                                            id="242976"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={9}
                                            xxl={9}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                alignItems: "center",
                                                textAlign: "-webkit-left",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          >
                                            <Label
                                              id="450852"
                                              value={ReactSystemFunctions.translate(
                                                this.ml,
                                                450852,
                                                "value",
                                                this.defaultML
                                              )}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  display: "inline",
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "12px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  letterSpacing: "0.5px"
                                                } as any
                                              }
                                            ></Label>
                                          </KCol>

                                          <KCol
                                            id="830418"
                                            xs={24}
                                            sm={24}
                                            md={24}
                                            lg={24}
                                            xl={15}
                                            xxl={15}
                                            order={0}
                                            pull={0}
                                            push={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                textAlign: "-webkit-left",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          >
                                            <Form.Item className="kFormItem" name="istakipkart_743413_value">
                                              <Checkbox
                                                id="743413"
                                                onChange={(e?: any) => {
                                                  if (e && e.stopPropagation) e.stopPropagation();
                                                  this.IsTakipKartComponent_743413_onChange();
                                                }}
                                                children={ReactSystemFunctions.translate(
                                                  this.ml,
                                                  743413,
                                                  "children",
                                                  this.defaultML
                                                )}
                                                disabled={false}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    letterSpacing: "0.5px"
                                                  } as any
                                                }
                                              ></Checkbox>
                                            </Form.Item>
                                          </KCol>
                                        </KRow>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="656459"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundColor: "rgba(239, 253, 223, 1)",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 16,
                                    paddingRight: 16,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="219677"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      height: "24px",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="305329"
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="665608"
                                    xs={6}
                                    sm={6}
                                    md={6}
                                    lg={6}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        textAlign: "-webkit-right",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KCol>
                                </KRow>

                                <KRow
                                  id="134883"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingBottom: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="473403"
                                    xs={9}
                                    sm={9}
                                    md={9}
                                    lg={9}
                                    xl={9}
                                    xxl={9}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="786823"
                                      value={ReactSystemFunctions.translate(this.ml, 786823, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="982380"
                                    xs={15}
                                    sm={15}
                                    md={15}
                                    lg={15}
                                    xl={15}
                                    xxl={15}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="253070"
                                      value={this.state.getBasvuruFormuById?.at?.(0)?.projeAd ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_204534_value">
                                      <TextInput
                                        id="204534"
                                        visibility={this.state.isComp204534Visible}
                                        kuikaRef={this.istakipkart_204534_value_kuikaTextInputRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          204534,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        bordered={true}
                                        disabled={false}
                                        type="text"
                                        iconColor="rgba(0, 0, 0, 1)"
                                        formatter="None"
                                        isCharOnly={false}
                                        autoComplete={true}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></TextInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="451131"
                                  visibility={this.state.isComp451131Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="227569"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="165083"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="936650"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="315469"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            315469,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="767418"
                                        xs={15}
                                        sm={15}
                                        md={15}
                                        lg={15}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="449363"
                                          visibility={this.state.isComp449363Visible}
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.yayinKanali ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="583165"
                                          visibility={this.state.isComp583165Visible}
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="896292"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="351334"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="984986"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            984986,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="940723"
                                        xs={15}
                                        sm={15}
                                        md={15}
                                        lg={15}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="741340"
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.sezon ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="638870"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="807454"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="207250"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            207250,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="542870"
                                        xs={15}
                                        sm={15}
                                        md={15}
                                        lg={15}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="397201"
                                          visibility={this.state.isComp397201Visible}
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.bolum ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="28971"
                                          visibility={this.state.isComp28971Visible}
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.diziBolumNo ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="217616"
                                  visibility={this.state.isComp217616Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="742629"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="692312"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="495728"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="323988"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            323988,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="260185"
                                        xs={15}
                                        sm={15}
                                        md={15}
                                        lg={15}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="218936"
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.konserYerTarih ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="103661"
                                  visibility={this.state.isComp103661Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="255357"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="318467"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="527986"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="105568"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            105568,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="793378"
                                        xs={15}
                                        sm={15}
                                        md={15}
                                        lg={15}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="309538"
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.urun ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="209641"
                                  visibility={this.state.isComp209641Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="597174"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="34273"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="496118"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="906518"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            906518,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="577847"
                                        xs={15}
                                        sm={15}
                                        md={15}
                                        lg={15}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "flex-start",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="401049"
                                          visibility={this.state.isComp401049Visible}
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.tvKanal ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="771200"
                                          visibility={this.state.isComp771200Visible}
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.yayinKanali ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="278772"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="276101"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="584575"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            584575,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="876987"
                                        xs={15}
                                        sm={15}
                                        md={15}
                                        lg={15}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="915575"
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.sezon ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="394626"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="520463"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="564757"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            564757,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="171636"
                                        xs={15}
                                        sm={15}
                                        md={15}
                                        lg={15}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="864345"
                                          visibility={this.state.isComp864345Visible}
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.tvBolumNo ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="260313"
                                          visibility={this.state.isComp260313Visible}
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.bolum ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="831469"
                                          visibility={this.state.isComp831469Visible}
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.diziBolumNo ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="377079"
                                  visibility={this.state.isComp377079Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="442591"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="632266"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="15930"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="42373"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            42373,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="904193"
                                        xs={15}
                                        sm={15}
                                        md={15}
                                        lg={15}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="883142"
                                          value={this.state.getBasvuruFormuById?.at?.(0)?.sanatcilar ?? undefined}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>

                                    <KRow
                                      id="628415"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingTop: 16,
                                          paddingBottom: 0,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="887791"
                                        xs={9}
                                        sm={9}
                                        md={9}
                                        lg={9}
                                        xl={9}
                                        xxl={9}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 16,
                                            paddingLeft: 16,
                                            textAlign: "-webkit-left",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="15363"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            15363,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>

                                      <KCol
                                        id="602891"
                                        xs={15}
                                        sm={15}
                                        md={15}
                                        lg={15}
                                        xl={15}
                                        xxl={15}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="427688"
                                          value={
                                            this.state.getBasvuruFormuById?.at?.(0)?.kullanimSekliCDTanim ?? undefined
                                          }
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 0,
                                              paddingRight: 0,
                                              paddingBottom: 0,
                                              paddingLeft: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "inline",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="845132"
                                  visibility={this.state.isComp845132Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="196781"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 16,
                                        paddingRight: 0,
                                        paddingBottom: 16,
                                        paddingLeft: 16,
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="642625"
                                      value={ReactSystemFunctions.translate(this.ml, 642625, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>

                                    <KTable
                                      id="73196"
                                      kuikaRef={this.istakipkart_73196_value_kuikaTableRef}
                                      form={this.props.form}
                                      dataSource={this.state.spgetListKullanimSureleriByBFId_brief}
                                      size="middle"
                                      bordered={true}
                                      showHeader={false}
                                      loading={false}
                                      nodatafoundmessage={ReactSystemFunctions.translate(
                                        this.ml,
                                        73196,
                                        "nodatafoundmessage",
                                        this.defaultML
                                      )}
                                      sorter={false}
                                      pagination={false}
                                      hideTableOnEmpty={true}
                                      striped={false}
                                      stripedColor="#F5F7FA"
                                      insertRowActive={false}
                                      searchable={false}
                                      filtering={false}
                                      transformedOnMobileResolution={false}
                                      tableWidthMode="fit"
                                      columnChooser={false}
                                      fixedHeader={false}
                                      editable={false}
                                      globalSearch={false}
                                      pageSize={10}
                                      showSizeChanger={false}
                                      resizableColumns={false}
                                      insertRowPosition="top"
                                      showNoDataFound={true}
                                      multiSelect={false}
                                      export={false}
                                      editableAlwaysActive={false}
                                      hideHeaderOnMobileResolution={false}
                                      style={
                                        {
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KTableHeader
                                        id="512538"
                                        hideOnMobileResolution={false}
                                        textDirection="Default"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0
                                          } as any
                                        }
                                      ></KTableHeader>

                                      <KTableRow
                                        id="612759"
                                        hoverFontColor="red"
                                        hoverBgColor="#F5F5F5"
                                        style={
                                          {
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KTableColumn
                                          id="358878"
                                          title={ReactSystemFunctions.translate(
                                            this.ml,
                                            358878,
                                            "title",
                                            this.defaultML
                                          )}
                                          alwaysVisibleOnMobileResolution={false}
                                          hideFiltering={false}
                                          hideSorting={false}
                                          hideSearch={false}
                                          footerOptions="none"
                                          footerFontSize={14}
                                          footerFontColor="rgb(68, 68, 68)"
                                          style={{} as any}
                                        >
                                          <KRow
                                            id="792907"
                                            align="top"
                                            justify="start"
                                            horizontalGutter={0}
                                            verticalGutter={0}
                                            style={
                                              {
                                                borderBottomWidth: 1,
                                                borderColor: "rgba(155, 155, 155, 1)",
                                                borderStyle: "dotted",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundColor: "rgba(239, 253, 223, 1)",
                                                paddingTop: 4,
                                                paddingBottom: 4,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          >
                                            <KCol
                                              id="882630"
                                              xs={24}
                                              sm={24}
                                              md={24}
                                              lg={24}
                                              xl={24}
                                              xxl={24}
                                              order={0}
                                              pull={0}
                                              push={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  paddingLeft: 8,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  letterSpacing: "0.5px"
                                                } as any
                                              }
                                            >
                                              <HorizontalStack
                                                id="197259"
                                                direction="horizontal"
                                                size={6}
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    paddingTop: 0,
                                                    paddingRight: 0,
                                                    paddingBottom: 0,
                                                    paddingLeft: 0,
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "14px",
                                                    color: "rgba(68, 68, 68, 1)",
                                                    letterSpacing: "0.5px"
                                                  } as any
                                                }
                                              >
                                                <Label
                                                  id="766577"
                                                  value="[datafield:sureSaat]"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      backgroundRepeat: "no-repeat",
                                                      backgroundSize: "contain",
                                                      backgroundPosition: "left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "12px",
                                                      color: "rgba(47, 47, 47, 1)",
                                                      letterSpacing: "0.5px"
                                                    } as any
                                                  }
                                                ></Label>

                                                <Label
                                                  id="92674"
                                                  value="[datafield:kTuru]"
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      backgroundRepeat: "no-repeat",
                                                      backgroundSize: "contain",
                                                      backgroundPosition: "left",
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "12px",
                                                      color: "rgba(47, 47, 47, 1)",
                                                      letterSpacing: "0.5px"
                                                    } as any
                                                  }
                                                ></Label>

                                                <Label
                                                  id="577917"
                                                  value="[datafield:muzikKod]"
                                                  maxLine={3}
                                                  style={
                                                    {
                                                      borderStyle: "solid",
                                                      borderTopWidth: 0,
                                                      borderRightWidth: 0,
                                                      borderBottomWidth: 0,
                                                      borderLeftWidth: 0,
                                                      backgroundRepeat: "no-repeat",
                                                      backgroundSize: "contain",
                                                      backgroundPosition: "left",
                                                      paddingTop: 3,
                                                      fontFamily: "Poppins",
                                                      fontWeight: 500,
                                                      fontSize: "12px",
                                                      color: "rgba(155, 155, 155, 1)",
                                                      letterSpacing: "0.5px"
                                                    } as any
                                                  }
                                                ></Label>
                                              </HorizontalStack>
                                            </KCol>
                                          </KRow>
                                        </KTableColumn>
                                      </KTableRow>
                                    </KTable>

                                    <Form.Item className="kFormItem" name="istakipkart_681754_value">
                                      <TextArea
                                        id="681754"
                                        visibility={this.state.isComp681754Visible}
                                        editability={this.state.isComp681754Enabled}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          681754,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoSize={false}
                                        showCount={false}
                                        formatter="None"
                                        readOnly={false}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(239, 253, 223, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "128px",
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)"
                                          } as any
                                        }
                                      ></TextArea>
                                    </Form.Item>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="464557"
                              visibility={this.state.isComp464557Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(252, 246, 236, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 16,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="14343"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="634112"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      alignItems: "center",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="726997"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="686802"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="488704"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(253, 252, 232, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingBottom: 16,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(47, 47, 47, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="343987"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="407747"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      alignItems: "center",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="604221"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={6}
                                    xxl={6}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="847627"
                                      value={ReactSystemFunctions.translate(this.ml, 847627, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          textAlign: "-webkit-left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_449550_value">
                                      <KDatePicker
                                        id="449550"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.IsTakipKartComponent_449550_onChange();
                                        }}
                                        kuikaRef={this.istakipkart_449550_value_kuikaDateRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          449550,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={true}
                                        format="DD/MM/YYYY"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KDatePicker>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="102171"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={6}
                                    xxl={6}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="761581"
                                      value={ReactSystemFunctions.translate(this.ml, 761581, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_218026_value">
                                      <KDatePicker
                                        id="218026"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.IsTakipKartComponent_218026_onChange();
                                        }}
                                        kuikaRef={this.istakipkart_218026_value_kuikaDateRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          218026,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={true}
                                        format="DD/MM/YYYY"
                                        disableBeforeDate={ReactSystemFunctions.value(
                                          this,
                                          "istakipkart_449550_value",
                                          "value"
                                        )}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KDatePicker>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="119140"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={6}
                                    xxl={6}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="97468"
                                      value={ReactSystemFunctions.translate(this.ml, 97468, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "block",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_345176_value">
                                      <KDatePicker
                                        id="345176"
                                        kuikaRef={this.istakipkart_345176_value_kuikaDateRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          345176,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={true}
                                        format="DD/MM/YYYY"
                                        disableBeforeDate={ReactSystemFunctions.value(
                                          this,
                                          "istakipkart_218026_value",
                                          "value"
                                        )}
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KDatePicker>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="831893"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={6}
                                    xxl={6}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KRow
                                      id="249912"
                                      align="top"
                                      justify="start"
                                      horizontalGutter={0}
                                      verticalGutter={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KCol
                                        id="324973"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingLeft: 16,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="451851"
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            451851,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Label>

                                        <Form.Item className="kFormItem" name="istakipkart_787576_value">
                                          <Checkbox
                                            id="787576"
                                            children={ReactSystemFunctions.translate(
                                              this.ml,
                                              787576,
                                              "children",
                                              this.defaultML
                                            )}
                                            disabled={false}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingLeft: 16,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          ></Checkbox>
                                        </Form.Item>
                                      </KCol>

                                      <KCol
                                        id="589056"
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        xxl={12}
                                        order={0}
                                        pull={0}
                                        push={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingRight: 10,
                                            alignItems: "flex-end",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="176618"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.IsTakipKartComponent_176618_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp176618Visible}
                                          iconName="autorenew"
                                          hoverText="Sözleşme Bilgilerini Güncelle"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              color: "rgba(172, 38, 42, 1)"
                                            } as any
                                          }
                                        ></Icon>
                                      </KCol>
                                    </KRow>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="701249"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderBottomRightRadius: 16,
                                  borderBottomLeftRadius: 16,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(248, 212, 150, 0.25)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 0,
                                  paddingRight: 16,
                                  paddingBottom: 16,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  textAlign: "-webkit-left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="692570"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={10}
                                xxl={10}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderTopLeftRadius: 12,
                                    borderTopRightRadius: 12,
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingRight: 4,
                                    alignItems: "flex-start",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="450613"
                                  value={ReactSystemFunctions.translate(this.ml, 450613, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="istakipkart_168257_value">
                                  <KSelectBox
                                    id="168257"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.IsTakipKartComponent_168257_onChange();
                                    }}
                                    editability={this.state.isComp168257Enabled}
                                    kuikaRef={this.istakipkart_168257_value_kuikaSelectBoxRef}
                                    options={this.state.getListCari}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      168257,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={true}
                                    autoClearSearchValue={true}
                                    showSearch={true}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="id"
                                    datatextfield="cariAd"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>

                                <KRow
                                  id="921407"
                                  visibility={this.state.isComp921407Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="387039"
                                    xs={22}
                                    sm={22}
                                    md={22}
                                    lg={22}
                                    xl={22}
                                    xxl={22}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingRight: 16,
                                        paddingLeft: 16,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="614206"
                                      value={ReactSystemFunctions.translate(this.ml, 614206, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          display: "inline",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="828112"
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    xxl={2}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_494755_value">
                                      <Checkbox
                                        id="494755"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.IsTakipKartComponent_494755_onChange();
                                        }}
                                        children={ReactSystemFunctions.translate(
                                          this.ml,
                                          494755,
                                          "children",
                                          this.defaultML
                                        )}
                                        disabled={false}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Checkbox>
                                    </Form.Item>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="728304"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "dotted",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 8,
                                    paddingLeft: 4,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="201254"
                                  value={ReactSystemFunctions.translate(this.ml, 201254, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="istakipkart_771873_value">
                                  <KSelectBox
                                    id="771873"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.IsTakipKartComponent_771873_onChange();
                                    }}
                                    editability={this.state.isComp771873Enabled}
                                    kuikaRef={this.istakipkart_771873_value_kuikaSelectBoxRef}
                                    options={this.state.sbLisansTipiList}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      771873,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="id"
                                    datatextfield="tanim"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>

                              <KCol
                                id="928031"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={8}
                                xxl={8}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="700915"
                                  value={ReactSystemFunctions.translate(this.ml, 700915, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <Form.Item className="kFormItem" name="istakipkart_780759_value">
                                  <KSelectBox
                                    id="780759"
                                    onChange={(e?: any) => {
                                      if (e && e.stopPropagation) e.stopPropagation();
                                      this.IsTakipKartComponent_780759_onChange();
                                    }}
                                    editability={this.state.isComp780759Enabled}
                                    kuikaRef={this.istakipkart_780759_value_kuikaSelectBoxRef}
                                    options={this.state.sbTelifTurList}
                                    placeholder={ReactSystemFunctions.translate(
                                      this.ml,
                                      780759,
                                      "placeholder",
                                      this.defaultML
                                    )}
                                    allowClear={false}
                                    autoClearSearchValue={true}
                                    showSearch={false}
                                    widthType="fill"
                                    containsNullItem={false}
                                    sortBy="none"
                                    datavaluefield="id"
                                    datatextfield="telifTanim"
                                    style={
                                      {
                                        borderBottomWidth: 1,
                                        borderColor: "rgba(155, 155, 155, 1)",
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        height: "32px",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(47, 47, 47, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KSelectBox>
                                </Form.Item>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="176459"
                          visibility={this.state.isComp176459Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 8,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="584322"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderTopLeftRadius: 16,
                                borderTopRightRadius: 16,
                                borderBottomRightRadius: 16,
                                borderBottomLeftRadius: 16,
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundColor: "rgba(74, 144, 226, 0.1)",
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="583303"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 8,
                                  alignItems: "flex-start",
                                  textAlign: "-webkit-left",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="499012"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="990778"
                                  value={ReactSystemFunctions.translate(this.ml, 990778, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <KRow
                                  id="948995"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="972638"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_953041_value">
                                      <KDatePicker
                                        id="953041"
                                        kuikaRef={this.istakipkart_953041_value_kuikaDateRef}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          953041,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={true}
                                        format="DD/MM/YYYY"
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            height: "32px",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KDatePicker>
                                    </Form.Item>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="952897"
                                xs={6}
                                sm={6}
                                md={6}
                                lg={6}
                                xl={6}
                                xxl={6}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="870787"
                                  value={ReactSystemFunctions.translate(this.ml, 870787, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>

                                <KRow
                                  id="326155"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="403276"
                                    xs={24}
                                    sm={24}
                                    md={24}
                                    lg={24}
                                    xl={24}
                                    xxl={24}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <HorizontalStack
                                      id="766869"
                                      direction="horizontal"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          alignItems: "center",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="istakipkart_552514_value">
                                        <NumberInput
                                          id="552514"
                                          decimalSeparator="."
                                          decimalScale={2}
                                          disabled={false}
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            552514,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          fixedDecimalScale={false}
                                          allowNegative={true}
                                          allowEmptyFormatting={false}
                                          isThousandSeperatorOn={true}
                                          style={
                                            {
                                              borderTopLeftRadius: 4,
                                              borderTopRightRadius: 4,
                                              borderBottomRightRadius: 4,
                                              borderBottomLeftRadius: 4,
                                              borderBottomWidth: 1,
                                              borderColor: "rgba(155, 155, 155, 1)",
                                              borderStyle: "dotted",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundColor: "rgba(255, 255, 255, 1)",
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              height: "32px",
                                              alignItems: "center",
                                              textAlign: "-webkit-right",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(47, 47, 47, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></NumberInput>
                                      </Form.Item>

                                      <Label
                                        id="73134"
                                        value={this.state.getBasvuruFormuById?.at?.(0)?.simge ?? undefined}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 0,
                                            paddingRight: 0,
                                            paddingBottom: 0,
                                            paddingLeft: 0,
                                            width: "100px",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            display: "inline",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </HorizontalStack>
                                  </KCol>
                                </KRow>
                              </KCol>

                              <KCol
                                id="19695"
                                xs={14}
                                sm={14}
                                md={14}
                                lg={14}
                                xl={14}
                                xxl={14}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "flex-start",
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="69013"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="815545"
                                    xs={20}
                                    sm={20}
                                    md={20}
                                    lg={20}
                                    xl={20}
                                    xxl={20}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KCol>

                                  <KCol
                                    id="395013"
                                    xs={4}
                                    sm={4}
                                    md={4}
                                    lg={4}
                                    xl={4}
                                    xxl={4}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        alignItems: "center",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></KCol>
                                </KRow>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="969"
                          visibility={this.state.isComp969Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="820095"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="163121"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 16,
                                  paddingBottom: 8,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="350005"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="474550"
                                  value={ReactSystemFunctions.translate(this.ml, 474550, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="422598"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    textAlign: "-webkit-left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Spacer id="725639" direction="horizontal" size={12} style={{} as any}>
                                  <Form.Item className="kFormItem" name="istakipkart_13037_value">
                                    <Checkbox
                                      id="13037"
                                      onChange={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IsTakipKartComponent_13037_onChange();
                                      }}
                                      children={ReactSystemFunctions.translate(
                                        this.ml,
                                        13037,
                                        "children",
                                        this.defaultML
                                      )}
                                      disabled={false}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingLeft: 12,
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></Checkbox>
                                  </Form.Item>

                                  <Label
                                    id="66331"
                                    value={ReactSystemFunctions.translate(this.ml, 66331, "value", this.defaultML)}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        display: "inline",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "12px",
                                        color: "rgba(245, 166, 35, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  ></Label>
                                </Spacer>
                              </KCol>
                            </KRow>

                            <KRow
                              id="995955"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 24,
                                  paddingBottom: 0,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="994642"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="838902"
                                  value={ReactSystemFunctions.translate(this.ml, 838902, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="466902"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="999090"
                                  visibility={this.state.isComp999090Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 999090, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="459375"
                                  visibility={this.state.isComp459375Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 459375, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(172, 38, 42, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="591113"
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="591201"
                                  value={ReactSystemFunctions.translate(this.ml, 591201, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="397186"
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="877336"
                                  value={ReactSystemFunctions.translate(this.ml, 877336, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="456100"
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="58056"
                                  value={ReactSystemFunctions.translate(this.ml, 58056, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="764379"
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="518152"
                          visibility={this.state.isComp518152Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 0,
                              paddingBottom: 24,
                              paddingLeft: 8,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="784561"
                            xs={4}
                            sm={4}
                            md={4}
                            lg={4}
                            xl={4}
                            xxl={4}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="istakipkart_748063_value">
                              <TextInput
                                id="748063"
                                kuikaRef={this.istakipkart_748063_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  748063,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    borderBottomRightRadius: 4,
                                    borderBottomLeftRadius: 4,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(211, 211, 211, 1)",
                                    borderStyle: "solid",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "32px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(74, 74, 74, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="257507"
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            xl={12}
                            xxl={12}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="istakipkart_515308_value">
                              <TextInput
                                id="515308"
                                kuikaRef={this.istakipkart_515308_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  515308,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    borderBottomRightRadius: 4,
                                    borderBottomLeftRadius: 4,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(211, 211, 211, 1)",
                                    borderStyle: "solid",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "32px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(74, 74, 74, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="812438"
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            xxl={2}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="istakipkart_77490_value">
                              <NumberInput
                                id="77490"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  77490,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    borderBottomRightRadius: 4,
                                    borderBottomLeftRadius: 4,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(211, 211, 211, 1)",
                                    borderStyle: "solid",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "32px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(74, 74, 74, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="267191"
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            xxl={2}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="istakipkart_471403_value">
                              <NumberInput
                                id="471403"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  471403,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    borderBottomRightRadius: 4,
                                    borderBottomLeftRadius: 4,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(211, 211, 211, 1)",
                                    borderStyle: "solid",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "32px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(74, 74, 74, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="599657"
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            xxl={2}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="istakipkart_462579_value">
                              <NumberInput
                                id="462579"
                                decimalSeparator="."
                                decimalScale={2}
                                disabled={false}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  462579,
                                  "placeholder",
                                  this.defaultML
                                )}
                                fixedDecimalScale={false}
                                allowNegative={true}
                                allowEmptyFormatting={false}
                                isThousandSeperatorOn={true}
                                style={
                                  {
                                    borderTopLeftRadius: 4,
                                    borderTopRightRadius: 4,
                                    borderBottomRightRadius: 4,
                                    borderBottomLeftRadius: 4,
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(211, 211, 211, 1)",
                                    borderStyle: "solid",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "32px",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "12px",
                                    color: "rgba(74, 74, 74, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></NumberInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="684857"
                            xs={2}
                            sm={2}
                            md={2}
                            lg={2}
                            xl={2}
                            xxl={2}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="104934"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="685255"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    textAlign: "-webkit-center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Button
                                  id="786097"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IsTakipKartComponent_786097_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 786097, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  icon="add"
                                  iconPosition="left"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 32,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 32,
                                      borderBottomLeftRadius: 32,
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundColor: "rgba(172, 38, 42, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 1,
                                      paddingRight: 6,
                                      paddingBottom: 1,
                                      paddingLeft: 6,
                                      display: "inline",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Button>
                              </KCol>

                              <KCol
                                id="121023"
                                xs={12}
                                sm={12}
                                md={12}
                                lg={12}
                                xl={12}
                                xxl={12}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>
                            </KRow>
                          </KCol>
                        </KRow>

                        <KRow
                          id="23905"
                          visibility={this.state.isComp23905Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="709183"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="151618"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-left",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="924320"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingTop: 8,
                                paddingRight: 8,
                                paddingBottom: 8,
                                paddingLeft: 8,
                                alignItems: "flex-start",
                                textAlign: "-webkit-right",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="istakipkart_445836_value">
                              <Image
                                id="445836"
                                onClick={(e?: any) => {
                                  if (e && e.stopPropagation) e.stopPropagation();
                                  this.IsTakipKartComponent_445836_onClick();
                                }}
                                showCursorPointer
                                placeHolderImage="data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAASABIAAD/7QA4UGhvdG9zaG9wIDMuMAA4QklNBAQAAAAAAAA4QklNBCUAAAAAABDUHYzZjwCyBOmACZjs+EJ+/9sAQwABAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEBAQEB/8AACwgANgBgAQERAP/EABkAAQADAQEAAAAAAAAAAAAAAAAEBQYHCv/EACoQAAEEAgMBAAADCQAAAAAAAAIAAwQFAQYREhMUByEiJDEzNjd1drS1/9oACAEBAAA/APWAiIiIiIiIiIiIiIiIiIqTZrU6HXNgvGmQkO01Ja2rbDhZAHzroD8wGTMeSAHCawBEOM5HBFnHOccKmbpdyyAZe3Zv2yI+vhrVeDHpxjv4g9JfdBrtz5i6+84IcYN1wsZMsq9c25FeRqnf4064oIdhNODN1IW4EvNSWG5zDcttyCE9qLKy3XWjlRPecqpb4MTMsysgyfUq6X99fAnefl9sOLL8u3fz+lgHvPv1Ht079e3Ue3HPGOeFMRERERY78RP6f71/h2z/APEnKlnWG4VVfI2+xkw40SL3lS9Od+BtqPTYHH6BvCHDh7QP8XqUrNG88WKdoBzlu7Wcfk1dY1d3kzU5Ott7TWXLNbbS7CU/jD9jg5BRbKnex8unzdkl4ZneMbsNrPFlu5dZvMx4jvVdc/l6h/s1X/osK5RERERV1xVx7uotKWWTgxbeunVcomSEXhjz4zsR4miITEXMNullssgY4LjORLGM4WAk6hu8+XVS7LctcsBpwA40OTo9h8Dtk3x53UuNG3mOMiyaxgvm7fsMNwykQ4LEkQfCxs9e3O6rp1RZbLqxV9nFfgzcRdHsG5XzSWyadzGcm7xZQ25AgWSYckwJjLbuANyM8I5AtvEjNQosaGx28IkdmMz2z2LyYbFpvsXGOxdAxznGMc5/PjH7lIREREREREREREREREREX//Z"
                                zoomOnClick={false}
                                imageFit="stretch"
                                style={
                                  {
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    height: "64px",
                                    display: "block"
                                  } as any
                                }
                              ></Image>
                            </Form.Item>
                          </KCol>
                        </KRow>

                        <KRow
                          id="575052"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="21742"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="160083"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="739682"
                            visibility={this.state.isComp739682Visible}
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                textAlign: "-webkit-right",
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Label
                              id="681149"
                              visibility={this.state.isComp681149Visible}
                              value={ReactSystemFunctions.translate(this.ml, 681149, "value", this.defaultML)}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingRight: 16,
                                  paddingLeft: 8,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "12px",
                                  color: "rgba(172, 38, 42, 1)"
                                } as any
                              }
                            ></Label>

                            <Button
                              id="202682"
                              onClick={(e?: any) => {
                                if (e && e.stopPropagation) e.stopPropagation();
                                this.IsTakipKartComponent_202682_onClick();
                              }}
                              showCursorPointer
                              visibility={this.state.isComp202682Visible}
                              label={ReactSystemFunctions.translate(this.ml, 202682, "label", this.defaultML)}
                              size="middle"
                              loading={false}
                              ghost={false}
                              block={false}
                              htmlType="button"
                              icon="add"
                              iconPosition="left"
                              danger={false}
                              style={
                                {
                                  borderTopLeftRadius: 32,
                                  borderTopRightRadius: 32,
                                  borderBottomRightRadius: 32,
                                  borderBottomLeftRadius: 32,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(172, 38, 42, 1)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 1,
                                  paddingRight: 6,
                                  paddingBottom: 1,
                                  paddingLeft: 6,
                                  display: "inline",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "16px",
                                  color: "rgba(255, 255, 255, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            ></Button>
                          </KCol>
                        </KRow>

                        <KRow
                          id="866931"
                          visibility={this.state.isComp866931Visible}
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderTopLeftRadius: 16,
                              borderTopRightRadius: 16,
                              borderBottomRightRadius: 16,
                              borderBottomLeftRadius: 16,
                              borderTopWidth: 1,
                              borderRightWidth: 1,
                              borderBottomWidth: 1,
                              borderLeftWidth: 1,
                              borderColor: "rgba(155, 155, 155, 1)",
                              borderStyle: "dotted",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingBottom: 16,
                              alignItems: "center",
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="450365"
                            xs={24}
                            sm={24}
                            md={24}
                            lg={24}
                            xl={24}
                            xxl={24}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "contain",
                                backgroundPosition: "left",
                                alignItems: "center",
                                textAlign: "-webkit-left",
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="348701"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderTopLeftRadius: 16,
                                  borderTopRightRadius: 16,
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundColor: "rgba(155, 155, 155, 0.17)",
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 8,
                                  paddingRight: 8,
                                  paddingBottom: 8,
                                  paddingLeft: 16,
                                  alignItems: "center",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="403507"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="13498"
                                  value={ReactSystemFunctions.translate(this.ml, 13498, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="799496"
                                xs={5}
                                sm={5}
                                md={5}
                                lg={5}
                                xl={5}
                                xxl={5}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="330703"
                                  visibility={this.state.isComp330703Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 330703, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="218524"
                                  visibility={this.state.isComp218524Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 218524, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="907269"
                                xs={3}
                                sm={3}
                                md={3}
                                lg={3}
                                xl={3}
                                xxl={3}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    textAlign: "-webkit-center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="945768"
                                  value={ReactSystemFunctions.translate(this.ml, 945768, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="782490"
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                xxl={1}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>

                              <KCol
                                id="487990"
                                xs={4}
                                sm={4}
                                md={4}
                                lg={4}
                                xl={4}
                                xxl={4}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    textAlign: "-webkit-center",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="991225"
                                  visibility={this.state.isComp991225Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 991225, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingLeft: 64,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="787604"
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 8,
                                    textAlign: "-webkit-right",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="476748"
                                  visibility={this.state.isComp476748Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 476748, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="681930"
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 8,
                                    textAlign: "-webkit-right",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="478750"
                                  visibility={this.state.isComp478750Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 478750, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>

                                <Label
                                  id="336407"
                                  visibility={this.state.isComp336407Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 336407, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="632914"
                                xs={2}
                                sm={2}
                                md={2}
                                lg={2}
                                xl={2}
                                xxl={2}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingRight: 8,
                                    textAlign: "-webkit-right",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="171984"
                                  value={ReactSystemFunctions.translate(this.ml, 171984, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "10px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Label>
                              </KCol>

                              <KCol
                                id="222690"
                                xs={1}
                                sm={1}
                                md={1}
                                lg={1}
                                xl={1}
                                xxl={1}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KCol>
                            </KRow>

                            <KTable
                              id="215911"
                              editability={this.state.isComp215911Enabled}
                              kuikaRef={this.istakipkart_215911_value_kuikaTableRef}
                              form={this.props.form}
                              dataSource={this.state.getListKullanimSureleriByBFId}
                              size="middle"
                              bordered={true}
                              loading={false}
                              transformedOnMobileResolution={false}
                              showHeader={false}
                              fixedHeader={false}
                              editable={false}
                              tableWidthMode="fit"
                              globalSearch={false}
                              searchable={false}
                              sorter={false}
                              filtering={false}
                              pagination={false}
                              pageSize={10}
                              showSizeChanger={false}
                              columnChooser={false}
                              resizableColumns={false}
                              striped={false}
                              stripedColor="#F5F7FA"
                              insertRowActive={false}
                              insertRowPosition="top"
                              showNoDataFound={true}
                              nodatafoundmessage={ReactSystemFunctions.translate(
                                this.ml,
                                215911,
                                "nodatafoundmessage",
                                this.defaultML
                              )}
                              multiSelect={false}
                              export={false}
                              editableAlwaysActive={false}
                              hideHeaderOnMobileResolution={false}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(155, 155, 155, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KTableHeader
                                id="788376"
                                hideOnMobileResolution={false}
                                textDirection="Default"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></KTableHeader>

                              <KTableRow
                                id="939842"
                                hoverFontColor="rgba(255, 255, 255, 1)"
                                hoverBgColor="rgba(231, 231, 230, 1)"
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopWidth: 1,
                                    borderColor: "rgba(204, 204, 204, 1)",
                                    borderStyle: "solid",
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTableColumn
                                  id="453635"
                                  title={ReactSystemFunctions.translate(this.ml, 453635, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 0,
                                      paddingRight: 0,
                                      paddingBottom: 0,
                                      paddingLeft: 0,
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="460143"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="414652"
                                      xs={24}
                                      sm={24}
                                      md={24}
                                      lg={24}
                                      xl={24}
                                      xxl={24}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KRow
                                        id="975148"
                                        visibility={this.state.isComp975148Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(rowData.groupRow, "1")
                                        }
                                        align="top"
                                        justify="start"
                                        horizontalGutter={0}
                                        verticalGutter={0}
                                        style={
                                          {
                                            borderBottomRightRadius: 16,
                                            borderBottomLeftRadius: 16,
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 254, 238, 1)",
                                            paddingTop: 8,
                                            paddingBottom: 8,
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KCol
                                          id="317550"
                                          xs={4}
                                          sm={4}
                                          md={4}
                                          lg={4}
                                          xl={4}
                                          xxl={4}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingLeft: 16,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <Label
                                            id="860332"
                                            value="[datafield:kTuru]"
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                backgroundRepeat: "no-repeat",
                                                backgroundSize: "contain",
                                                backgroundPosition: "left",
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "12px",
                                                color: "rgba(47, 47, 47, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          ></Label>
                                        </KCol>

                                        <KCol
                                          id="533704"
                                          xs={19}
                                          sm={19}
                                          md={19}
                                          lg={19}
                                          xl={19}
                                          xxl={19}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <HorizontalStack
                                            id="605922"
                                            direction="horizontal"
                                            size={2}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                paddingTop: 0,
                                                paddingRight: 0,
                                                paddingBottom: 0,
                                                paddingLeft: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          >
                                            <Icon
                                              id="691652"
                                              onClick={(e?: any) => {
                                                if (e && e.stopPropagation) e.stopPropagation();
                                                this.IsTakipKartComponent_691652_onClick();
                                              }}
                                              showCursorPointer
                                              visibility={this.state.isComp691652Visible}
                                              visibilityCondition={(rowData: object) =>
                                                ReactSystemFunctions.isEqualTo(
                                                  this.state.getFormNo[0]?.formNo?.toString(),
                                                  "0"
                                                )
                                              }
                                              iconName="format_list_bulleted"
                                              hoverText="Müzik Ekle"
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  alignItems: "center",
                                                  fontWeight: 500,
                                                  fontSize: "16px",
                                                  color: "rgba(208, 2, 27, 1)",
                                                  letterSpacing: "0.5px"
                                                } as any
                                              }
                                            ></Icon>

                                            <Label
                                              id="688538"
                                              value="[datafield:tablerowStokAd]"
                                              maxLine={3}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  backgroundRepeat: "no-repeat",
                                                  backgroundSize: "contain",
                                                  backgroundPosition: "left",
                                                  paddingTop: 4,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "12px",
                                                  color: "rgba(172, 38, 42, 1)",
                                                  letterSpacing: "0.5px"
                                                } as any
                                              }
                                            ></Label>
                                          </HorizontalStack>
                                        </KCol>

                                        <KCol
                                          id="941121"
                                          xs={1}
                                          sm={1}
                                          md={1}
                                          lg={1}
                                          xl={1}
                                          xxl={1}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></KCol>
                                      </KRow>
                                    </KCol>
                                  </KRow>

                                  <KRow
                                    id="434093"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        paddingTop: 8,
                                        paddingBottom: 8,
                                        alignItems: "center",
                                        fontWeight: 500,
                                        fontSize: "24px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="592607"
                                      xs={1}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                      xxl={1}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    ></KCol>

                                    <KCol
                                      id="195857"
                                      xs={9}
                                      sm={9}
                                      md={9}
                                      lg={9}
                                      xl={9}
                                      xxl={9}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <KRow
                                        id="759903"
                                        align="top"
                                        justify="start"
                                        horizontalGutter={0}
                                        verticalGutter={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            alignItems: "center",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <KCol
                                          id="154458"
                                          xs={24}
                                          sm={24}
                                          md={24}
                                          lg={24}
                                          xl={24}
                                          xxl={24}
                                          order={0}
                                          pull={0}
                                          push={0}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              alignItems: "center",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        >
                                          <KRow
                                            id="202449"
                                            align="top"
                                            justify="start"
                                            horizontalGutter={0}
                                            verticalGutter={0}
                                            style={
                                              {
                                                borderStyle: "solid",
                                                borderTopWidth: 0,
                                                borderRightWidth: 0,
                                                borderBottomWidth: 0,
                                                borderLeftWidth: 0,
                                                fontFamily: "Poppins",
                                                fontWeight: 500,
                                                fontSize: "14px",
                                                color: "rgba(68, 68, 68, 1)",
                                                letterSpacing: "0.5px"
                                              } as any
                                            }
                                          >
                                            <KCol
                                              id="447466"
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              xl={12}
                                              xxl={12}
                                              order={0}
                                              pull={0}
                                              push={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  letterSpacing: "0.5px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="129398"
                                                value="[datafield:mecra]"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "contain",
                                                    backgroundPosition: "left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "12px",
                                                    color: "rgba(47, 47, 47, 1)",
                                                    letterSpacing: "0.5px"
                                                  } as any
                                                }
                                              ></Label>
                                            </KCol>

                                            <KCol
                                              id="54223"
                                              xs={12}
                                              sm={12}
                                              md={12}
                                              lg={12}
                                              xl={12}
                                              xxl={12}
                                              order={0}
                                              pull={0}
                                              push={0}
                                              style={
                                                {
                                                  borderStyle: "solid",
                                                  borderTopWidth: 0,
                                                  borderRightWidth: 0,
                                                  borderBottomWidth: 0,
                                                  borderLeftWidth: 0,
                                                  fontFamily: "Poppins",
                                                  fontWeight: 500,
                                                  fontSize: "14px",
                                                  color: "rgba(68, 68, 68, 1)",
                                                  letterSpacing: "0.5px"
                                                } as any
                                              }
                                            >
                                              <Label
                                                id="104971"
                                                value="[datafield:bolge]"
                                                style={
                                                  {
                                                    borderStyle: "solid",
                                                    borderTopWidth: 0,
                                                    borderRightWidth: 0,
                                                    borderBottomWidth: 0,
                                                    borderLeftWidth: 0,
                                                    backgroundRepeat: "no-repeat",
                                                    backgroundSize: "contain",
                                                    backgroundPosition: "left",
                                                    fontFamily: "Poppins",
                                                    fontWeight: 500,
                                                    fontSize: "12px",
                                                    color: "rgba(47, 47, 47, 1)",
                                                    letterSpacing: "0.5px"
                                                  } as any
                                                }
                                              ></Label>
                                            </KCol>
                                          </KRow>
                                        </KCol>
                                      </KRow>
                                    </KCol>

                                    <KCol
                                      id="437082"
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      xxl={2}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="170851"
                                        value="[datafield:adet]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 8,
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="80694"
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      xxl={2}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="504748"
                                        value="[datafield:kSure]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 8,
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="264565"
                                      xs={3}
                                      sm={3}
                                      md={3}
                                      lg={3}
                                      xl={3}
                                      xxl={3}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          textAlign: "-webkit-center",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <HorizontalStack
                                        id="406828"
                                        direction="horizontal"
                                        size={0}
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            alignItems: "center",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      >
                                        <Label
                                          id="852077"
                                          visibility={this.state.isComp852077Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.getBasvuruFormuById[0]?.basvuruTuruKod?.toString(),
                                              "1"
                                            )
                                          }
                                          value="[datafield:tSn]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingRight: 2,
                                              textAlign: "-webkit-right",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="13593"
                                          visibility={this.state.isComp13593Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.getBasvuruFormuById[0]?.basvuruTuruKod?.toString(),
                                              "1"
                                            )
                                          }
                                          value={ReactSystemFunctions.translate(
                                            this.ml,
                                            13593,
                                            "value",
                                            this.defaultML
                                          )}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingRight: 2,
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="695930"
                                          visibility={this.state.isComp695930Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.getBasvuruFormuById[0]?.basvuruTuruKod?.toString(),
                                              "1"
                                            )
                                          }
                                          value="[datafield:hsn]"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingRight: 4,
                                              textAlign: "-webkit-right",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "12px",
                                              color: "rgba(31, 52, 251, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>

                                        <Label
                                          id="156094"
                                          value="[datafield:fiyatlandirmaTipiTanim]"
                                          maxLine={3}
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              paddingTop: 4,
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "10px",
                                              color: "rgba(155, 155, 155, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Label>
                                      </HorizontalStack>
                                    </KCol>

                                    <KCol
                                      id="493618"
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      xxl={2}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="89387"
                                        value="[datafield:fiyat]"
                                        formatter="money-var-1"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 8,
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="290565"
                                        value="[datafield:simge1]"
                                        formatter=""
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 8,
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="935455"
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      xxl={2}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="109511"
                                        value="[datafield:fiyatOzel]"
                                        formatter="money-var-1"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 8,
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(247, 161, 19, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="343505"
                                        visibility={this.state.isComp343505Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEmpty(rowData.fiyatOzel, "")
                                        }
                                        value="[datafield:simge2]"
                                        formatter=""
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 8,
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(247, 161, 19, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="64509"
                                      xs={2}
                                      sm={2}
                                      md={2}
                                      lg={2}
                                      xl={2}
                                      xxl={2}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="267908"
                                        value="[datafield:tutar]"
                                        formatter="money-var-1"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 8,
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="496326"
                                        value="[datafield:simge2]"
                                        formatter=""
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 8,
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>

                                    <KCol
                                      id="74391"
                                      xs={1}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                      xxl={1}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          paddingRight: 4,
                                          textAlign: "-webkit-right",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Spacer
                                        id="661921"
                                        direction="horizontal"
                                        size={12}
                                        style={
                                          {
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingRight: 8,
                                            alignItems: "flex-start"
                                          } as any
                                        }
                                      >
                                        <Icon
                                          id="846928"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.IsTakipKartComponent_846928_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp846928Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.getBasvuruFormuById[0]?.formReadOnly?.toString(),
                                              "0"
                                            )
                                          }
                                          iconName="delete"
                                          hoverText="Sil"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontWeight: 500,
                                              fontSize: "16px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>

                                        <Icon
                                          id="781928"
                                          onClick={(e?: any) => {
                                            if (e && e.stopPropagation) e.stopPropagation();
                                            this.IsTakipKartComponent_781928_onClick();
                                          }}
                                          showCursorPointer
                                          visibility={this.state.isComp781928Visible}
                                          visibilityCondition={(rowData: object) =>
                                            ReactSystemFunctions.isEqualTo(
                                              this.state.getBasvuruFormuById[0]?.formReadOnly?.toString(),
                                              "0"
                                            )
                                          }
                                          iconName="create"
                                          hoverText="Fiyat/Bilgi Ekle"
                                          style={
                                            {
                                              borderStyle: "solid",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderBottomWidth: 0,
                                              borderLeftWidth: 0,
                                              backgroundRepeat: "no-repeat",
                                              backgroundSize: "contain",
                                              backgroundPosition: "left",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(172, 38, 42, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></Icon>
                                      </Spacer>
                                    </KCol>
                                  </KRow>
                                </KTableColumn>
                              </KTableRow>
                            </KTable>
                          </KCol>
                        </KRow>

                        <KRow
                          id="774544"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              fontFamily: "Poppins",
                              fontWeight: 500,
                              fontSize: "14px",
                              color: "rgba(68, 68, 68, 1)",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="625087"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <Form.Item className="kFormItem" name="istakipkart_684788_value">
                              <TextInput
                                id="684788"
                                visibility={this.state.isComp684788Visible}
                                kuikaRef={this.istakipkart_684788_value_kuikaTextInputRef}
                                placeholder={ReactSystemFunctions.translate(
                                  this.ml,
                                  684788,
                                  "placeholder",
                                  this.defaultML
                                )}
                                allowClear={false}
                                bordered={true}
                                disabled={false}
                                type="text"
                                iconColor="rgba(0, 0, 0, 1)"
                                formatter="None"
                                isCharOnly={false}
                                autoComplete={true}
                                style={
                                  {
                                    borderTopLeftRadius: 0,
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    borderTopWidth: 1,
                                    borderColor: "rgba(204, 204, 204, 1)",
                                    borderStyle: "solid",
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    paddingTop: 8,
                                    paddingRight: 8,
                                    paddingBottom: 8,
                                    paddingLeft: 8,
                                    alignItems: "center",
                                    textAlign: "-webkit-left",
                                    display: "block",
                                    fontFamily: "Montserrat",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(1, 1, 1, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              ></TextInput>
                            </Form.Item>
                          </KCol>

                          <KCol
                            id="710679"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>

                          <KCol
                            id="799576"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                fontFamily: "Poppins",
                                fontWeight: 500,
                                fontSize: "14px",
                                color: "rgba(68, 68, 68, 1)",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          ></KCol>
                        </KRow>

                        <KRow
                          id="358531"
                          align="top"
                          justify="start"
                          horizontalGutter={0}
                          verticalGutter={0}
                          style={
                            {
                              borderBottomRightRadius: 12,
                              borderBottomLeftRadius: 12,
                              borderStyle: "solid",
                              borderTopWidth: 0,
                              borderRightWidth: 0,
                              borderBottomWidth: 0,
                              borderLeftWidth: 0,
                              backgroundColor: "rgba(255, 255, 255, 1)",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "contain",
                              backgroundPosition: "left",
                              paddingTop: 16,
                              paddingBottom: 16,
                              fontWeight: 500,
                              fontSize: "14px",
                              letterSpacing: "0.5px"
                            } as any
                          }
                        >
                          <KCol
                            id="895675"
                            xs={16}
                            sm={16}
                            md={16}
                            lg={16}
                            xl={16}
                            xxl={16}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="372010"
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  paddingTop: 16,
                                  paddingBottom: 8,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="373820"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    textAlign: "-webkit-left",
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Label
                                  id="369885"
                                  visibility={this.state.isComp369885Visible}
                                  value={ReactSystemFunctions.translate(this.ml, 369885, "value", this.defaultML)}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "12px",
                                      color: "rgba(172, 38, 42, 1)"
                                    } as any
                                  }
                                ></Label>
                              </KCol>
                            </KRow>

                            <KTable
                              id="959874"
                              kuikaRef={this.istakipkart_959874_value_kuikaTableRef}
                              form={this.props.form}
                              dataSource={this.state.getListFiyatlandirmaNotlar}
                              size="middle"
                              bordered={true}
                              showHeader={false}
                              loading={false}
                              nodatafoundmessage={ReactSystemFunctions.translate(
                                this.ml,
                                959874,
                                "nodatafoundmessage",
                                this.defaultML
                              )}
                              sorter={false}
                              pagination={false}
                              hideTableOnEmpty={true}
                              striped={false}
                              stripedColor="#F5F7FA"
                              insertRowActive={false}
                              searchable={false}
                              filtering={false}
                              transformedOnMobileResolution={false}
                              tableWidthMode="fit"
                              columnChooser={false}
                              fixedHeader={false}
                              editable={false}
                              globalSearch={false}
                              pageSize={10}
                              showSizeChanger={false}
                              resizableColumns={false}
                              insertRowPosition="top"
                              showNoDataFound={true}
                              multiSelect={false}
                              export={false}
                              editableAlwaysActive={false}
                              hideHeaderOnMobileResolution={false}
                              style={
                                {
                                  borderTopWidth: 1,
                                  borderRightWidth: 1,
                                  borderBottomWidth: 1,
                                  borderLeftWidth: 1,
                                  borderColor: "rgba(218, 218, 218, 1)",
                                  borderStyle: "solid",
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KTableHeader
                                id="637776"
                                hideOnMobileResolution={false}
                                textDirection="Default"
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0
                                  } as any
                                }
                              ></KTableHeader>

                              <KTableRow
                                id="821665"
                                hoverFontColor="red"
                                hoverBgColor="#F5F5F5"
                                style={
                                  {
                                    borderTopWidth: 1,
                                    borderRightWidth: 1,
                                    borderBottomWidth: 1,
                                    borderLeftWidth: 1,
                                    borderColor: "rgba(218, 218, 218, 1)",
                                    borderStyle: "solid",
                                    paddingTop: 0,
                                    paddingRight: 0,
                                    paddingBottom: 0,
                                    paddingLeft: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KTableColumn
                                  id="438580"
                                  title={ReactSystemFunctions.translate(this.ml, 438580, "title", this.defaultML)}
                                  alwaysVisibleOnMobileResolution={false}
                                  hideFiltering={false}
                                  hideSorting={false}
                                  hideSearch={false}
                                  footerOptions="none"
                                  footerFontSize={14}
                                  footerFontColor="rgb(68, 68, 68)"
                                  style={
                                    {
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(255, 255, 255, 1)",
                                      borderStyle: "solid",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KRow
                                    id="476904"
                                    align="top"
                                    justify="start"
                                    horizontalGutter={0}
                                    verticalGutter={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundColor: "rgba(255, 255, 255, 1)",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <KCol
                                      id="615854"
                                      xs={1}
                                      sm={1}
                                      md={1}
                                      lg={1}
                                      xl={1}
                                      xxl={1}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-right",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Icon
                                        id="939691"
                                        visibility={this.state.isComp939691Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(rowData.onemli, "true")
                                        }
                                        iconName="priority_high"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            fontSize: "24px",
                                            color: "rgba(172, 38, 42, 1)"
                                          } as any
                                        }
                                      ></Icon>
                                    </KCol>

                                    <KCol
                                      id="412376"
                                      xs={23}
                                      sm={23}
                                      md={23}
                                      lg={23}
                                      xl={23}
                                      xxl={23}
                                      order={0}
                                      pull={0}
                                      push={0}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          alignItems: "center",
                                          textAlign: "-webkit-left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Label
                                        id="115361"
                                        visibility={this.state.isComp115361Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isEqualTo(rowData.baslik, "true")
                                        }
                                        value="[datafield:aciklama]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 4,
                                            paddingLeft: 4,
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 700,
                                            fontSize: "13px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>

                                      <Label
                                        id="809063"
                                        visibility={this.state.isComp809063Visible}
                                        visibilityCondition={(rowData: object) =>
                                          ReactSystemFunctions.isNotEqualTo(rowData.baslik, "true")
                                        }
                                        value="[datafield:aciklama]"
                                        style={
                                          {
                                            borderStyle: "solid",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderBottomWidth: 0,
                                            borderLeftWidth: 0,
                                            paddingTop: 4,
                                            paddingLeft: 8,
                                            display: "block",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "12px",
                                            color: "rgba(47, 47, 47, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></Label>
                                    </KCol>
                                  </KRow>
                                </KTableColumn>
                              </KTableRow>
                            </KTable>
                          </KCol>

                          <KCol
                            id="979867"
                            xs={8}
                            sm={8}
                            md={8}
                            lg={8}
                            xl={8}
                            xxl={8}
                            order={0}
                            pull={0}
                            push={0}
                            style={
                              {
                                borderStyle: "solid",
                                borderTopWidth: 0,
                                borderRightWidth: 0,
                                borderBottomWidth: 0,
                                borderLeftWidth: 0,
                                paddingRight: 16,
                                paddingLeft: 16,
                                fontWeight: 500,
                                fontSize: "14px",
                                letterSpacing: "0.5px"
                              } as any
                            }
                          >
                            <KRow
                              id="794716"
                              visibility={this.state.isComp794716Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  fontFamily: "Poppins",
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  color: "rgba(68, 68, 68, 1)",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="786863"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    fontFamily: "Poppins",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    color: "rgba(68, 68, 68, 1)",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="30512"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 16,
                                      paddingBottom: 8,
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="967805"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="948285"
                                      value={ReactSystemFunctions.translate(this.ml, 948285, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="111032"
                                    xs={12}
                                    sm={12}
                                    md={12}
                                    lg={12}
                                    xl={12}
                                    xxl={12}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        textAlign: "-webkit-right",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="256375"
                                      value={this.state.getListKullanimSureleriByBFId?.at?.(0)?.simge ?? undefined}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="839278"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="997078"
                                    xs={14}
                                    sm={14}
                                    md={14}
                                    lg={14}
                                    xl={14}
                                    xxl={14}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="742579"
                                      value={ReactSystemFunctions.translate(this.ml, 742579, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="755163"
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    xxl={10}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_649647_value">
                                      <NumberInput
                                        id="649647"
                                        decimalSeparator="."
                                        decimalScale={2}
                                        disabled={true}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        allowEmptyFormatting={false}
                                        isThousandSeperatorOn={true}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(211, 211, 211, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "13px",
                                            color: "rgba(74, 74, 74, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></NumberInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="122685"
                                  visibility={this.state.isComp122685Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="961917"
                                    xs={14}
                                    sm={14}
                                    md={14}
                                    lg={14}
                                    xl={14}
                                    xxl={14}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="960811"
                                      value={ReactSystemFunctions.translate(this.ml, 960811, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="560129"
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    xxl={10}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_952267_value">
                                      <NumberInput
                                        id="952267"
                                        decimalSeparator="."
                                        decimalScale={2}
                                        disabled={true}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        allowEmptyFormatting={false}
                                        isThousandSeperatorOn={true}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(211, 211, 211, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "13px",
                                            color: "rgba(74, 74, 74, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></NumberInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="104543"
                                  visibility={this.state.isComp104543Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="520689"
                                    xs={7}
                                    sm={7}
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="637169"
                                      value={ReactSystemFunctions.translate(this.ml, 637169, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="231702"
                                    xs={7}
                                    sm={7}
                                    md={7}
                                    lg={7}
                                    xl={7}
                                    xxl={7}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_161516_value">
                                      <KSelectBox
                                        id="161516"
                                        onChange={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.IsTakipKartComponent_161516_onChange();
                                        }}
                                        kuikaRef={this.istakipkart_161516_value_kuikaSelectBoxRef}
                                        options={this.state.sbStopajList}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          161516,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={false}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="stopajOran"
                                        datatextfield="stopajOran"
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "13px",
                                            color: "rgba(74, 74, 74, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="835202"
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    xxl={10}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_830227_value">
                                      <NumberInput
                                        id="830227"
                                        decimalSeparator="."
                                        decimalScale={2}
                                        disabled={true}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        allowEmptyFormatting={false}
                                        isThousandSeperatorOn={true}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(211, 211, 211, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "13px",
                                            color: "rgba(74, 74, 74, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></NumberInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="2269"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="897877"
                                    xs={14}
                                    sm={14}
                                    md={14}
                                    lg={14}
                                    xl={14}
                                    xxl={14}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="734427"
                                      visibility={this.state.isComp734427Visible}
                                      value={ReactSystemFunctions.translate(this.ml, 734427, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="586759"
                                      visibility={this.state.isComp586759Visible}
                                      value={ReactSystemFunctions.translate(this.ml, 586759, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Label
                                      id="326018"
                                      visibility={this.state.isComp326018Visible}
                                      value={ReactSystemFunctions.translate(this.ml, 326018, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="962889"
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    xxl={10}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_20311_value">
                                      <NumberInput
                                        id="20311"
                                        decimalSeparator="."
                                        decimalScale={2}
                                        disabled={true}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        allowEmptyFormatting={false}
                                        isThousandSeperatorOn={true}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(211, 211, 211, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "13px",
                                            color: "rgba(74, 74, 74, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></NumberInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="949335"
                                  visibility={this.state.isComp949335Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="484680"
                                    xs={14}
                                    sm={14}
                                    md={14}
                                    lg={14}
                                    xl={14}
                                    xxl={14}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="502118"
                                      value={ReactSystemFunctions.translate(this.ml, 502118, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="262286"
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    xxl={10}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_368022_value">
                                      <NumberInput
                                        id="368022"
                                        onPressEnter={(e?: any) => {
                                          if (e && e.stopPropagation) e.stopPropagation();
                                          this.IsTakipKartComponent_368022_onPressEnter();
                                        }}
                                        decimalSeparator="."
                                        decimalScale={2}
                                        disabled={false}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        allowEmptyFormatting={false}
                                        isThousandSeperatorOn={true}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(211, 211, 211, 1)",
                                            borderStyle: "solid",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "13px",
                                            color: "rgba(74, 74, 74, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></NumberInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="797216"
                                  visibility={this.state.isComp797216Visible}
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="515126"
                                    xs={14}
                                    sm={14}
                                    md={14}
                                    lg={14}
                                    xl={14}
                                    xxl={14}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="959223"
                                      value={ReactSystemFunctions.translate(this.ml, 959223, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="554208"
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    xxl={10}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_241361_value">
                                      <NumberInput
                                        id="241361"
                                        decimalSeparator="."
                                        decimalScale={2}
                                        disabled={true}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        allowEmptyFormatting={false}
                                        isThousandSeperatorOn={true}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(211, 211, 211, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "13px",
                                            color: "rgba(74, 74, 74, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></NumberInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="375125"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="833797"
                                    xs={14}
                                    sm={14}
                                    md={14}
                                    lg={14}
                                    xl={14}
                                    xxl={14}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="541448"
                                      value={ReactSystemFunctions.translate(this.ml, 541448, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="199808"
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    xxl={10}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_286867_value">
                                      <NumberInput
                                        id="286867"
                                        decimalSeparator="."
                                        decimalScale={2}
                                        disabled={true}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        allowEmptyFormatting={false}
                                        isThousandSeperatorOn={true}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(211, 211, 211, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "13px",
                                            color: "rgba(74, 74, 74, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></NumberInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="675606"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      alignItems: "center",
                                      textAlign: "-webkit-left",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="400468"
                                    xs={14}
                                    sm={14}
                                    md={14}
                                    lg={14}
                                    xl={14}
                                    xxl={14}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "dotted",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="720880"
                                      value={ReactSystemFunctions.translate(this.ml, 720880, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>
                                  </KCol>

                                  <KCol
                                    id="712767"
                                    xs={10}
                                    sm={10}
                                    md={10}
                                    lg={10}
                                    xl={10}
                                    xxl={10}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        backgroundRepeat: "no-repeat",
                                        backgroundSize: "contain",
                                        backgroundPosition: "left",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Form.Item className="kFormItem" name="istakipkart_228291_value">
                                      <NumberInput
                                        id="228291"
                                        decimalSeparator="."
                                        decimalScale={2}
                                        disabled={true}
                                        fixedDecimalScale={true}
                                        allowNegative={true}
                                        allowEmptyFormatting={false}
                                        isThousandSeperatorOn={true}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(211, 211, 211, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            height: "32px",
                                            textAlign: "-webkit-right",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "13px",
                                            color: "rgba(74, 74, 74, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></NumberInput>
                                    </Form.Item>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="593330"
                              visibility={this.state.isComp593330Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 48,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="457079"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <KRow
                                  id="937747"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="914510"
                                    xs={22}
                                    sm={22}
                                    md={22}
                                    lg={22}
                                    xl={22}
                                    xxl={22}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="905699"
                                      value={ReactSystemFunctions.translate(this.ml, 905699, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_353223_value">
                                      <TextArea
                                        id="353223"
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          353223,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoSize={false}
                                        showCount={false}
                                        formatter="None"
                                        readOnly={false}
                                        style={
                                          {
                                            borderTopLeftRadius: 4,
                                            borderTopRightRadius: 4,
                                            borderBottomRightRadius: 4,
                                            borderBottomLeftRadius: 4,
                                            borderTopWidth: 1,
                                            borderRightWidth: 1,
                                            borderBottomWidth: 1,
                                            borderLeftWidth: 1,
                                            borderColor: "rgba(218, 218, 218, 1)",
                                            borderStyle: "solid",
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            height: "128px",
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></TextArea>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="414339"
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    xxl={2}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 0,
                                        paddingLeft: 8,
                                        alignItems: "flex-end",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="19843"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IsTakipKartComponent_19843_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp19843Visible}
                                      iconName="autorenew"
                                      hoverText="Açıklama Güncelle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="393033"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 16,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="991805"
                                    xs={22}
                                    sm={22}
                                    md={22}
                                    lg={22}
                                    xl={22}
                                    xxl={22}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="175543"
                                      value={ReactSystemFunctions.translate(this.ml, 175543, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <Form.Item className="kFormItem" name="istakipkart_116506_value">
                                      <KSelectBox
                                        id="116506"
                                        kuikaRef={this.istakipkart_116506_value_kuikaSelectBoxRef}
                                        options={this.state.sbVadeGunList}
                                        placeholder={ReactSystemFunctions.translate(
                                          this.ml,
                                          116506,
                                          "placeholder",
                                          this.defaultML
                                        )}
                                        allowClear={false}
                                        autoClearSearchValue={true}
                                        showSearch={false}
                                        widthType="fill"
                                        containsNullItem={false}
                                        sortBy="none"
                                        datavaluefield="id"
                                        datatextfield="vadeGun"
                                        style={
                                          {
                                            borderBottomWidth: 1,
                                            borderColor: "rgba(155, 155, 155, 1)",
                                            borderStyle: "dotted",
                                            borderTopWidth: 0,
                                            borderRightWidth: 0,
                                            borderLeftWidth: 0,
                                            backgroundColor: "rgba(255, 255, 255, 1)",
                                            backgroundRepeat: "no-repeat",
                                            backgroundSize: "contain",
                                            backgroundPosition: "left",
                                            paddingTop: 8,
                                            paddingRight: 8,
                                            paddingBottom: 8,
                                            paddingLeft: 8,
                                            alignItems: "center",
                                            textAlign: "-webkit-left",
                                            fontFamily: "Poppins",
                                            fontWeight: 500,
                                            fontSize: "14px",
                                            color: "rgba(68, 68, 68, 1)",
                                            letterSpacing: "0.5px"
                                          } as any
                                        }
                                      ></KSelectBox>
                                    </Form.Item>
                                  </KCol>

                                  <KCol
                                    id="230649"
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    xxl={2}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 2,
                                        paddingLeft: 8,
                                        alignItems: "flex-end",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="436915"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IsTakipKartComponent_436915_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp436915Visible}
                                      iconName="autorenew"
                                      hoverText="Vade Gün Güncelle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>

                                <KRow
                                  id="775715"
                                  align="top"
                                  justify="start"
                                  horizontalGutter={0}
                                  verticalGutter={0}
                                  style={
                                    {
                                      borderStyle: "solid",
                                      borderTopWidth: 0,
                                      borderRightWidth: 0,
                                      borderBottomWidth: 0,
                                      borderLeftWidth: 0,
                                      paddingTop: 16,
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "14px",
                                      color: "rgba(68, 68, 68, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                >
                                  <KCol
                                    id="999584"
                                    xs={22}
                                    sm={22}
                                    md={22}
                                    lg={22}
                                    xl={22}
                                    xxl={22}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        textAlign: "-webkit-left",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Label
                                      id="186221"
                                      value={ReactSystemFunctions.translate(this.ml, 186221, "value", this.defaultML)}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          backgroundRepeat: "no-repeat",
                                          backgroundSize: "contain",
                                          backgroundPosition: "left",
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "rgba(0, 0, 0, 1)"
                                        } as any
                                      }
                                    ></Label>

                                    <VerticalStack
                                      id="600585"
                                      direction="vertical"
                                      size={12}
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          paddingTop: 0,
                                          paddingRight: 0,
                                          paddingBottom: 0,
                                          paddingLeft: 0,
                                          fontFamily: "Poppins",
                                          fontWeight: 500,
                                          fontSize: "14px",
                                          color: "rgba(68, 68, 68, 1)",
                                          letterSpacing: "0.5px"
                                        } as any
                                      }
                                    >
                                      <Form.Item className="kFormItem" name="istakipkart_624781_value">
                                        <TextInput
                                          id="624781"
                                          kuikaRef={this.istakipkart_624781_value_kuikaTextInputRef}
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            624781,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          maxLength={50}
                                          allowClear={false}
                                          bordered={true}
                                          disabled={false}
                                          type="text"
                                          iconColor="rgba(0, 0, 0, 1)"
                                          formatter="None"
                                          isCharOnly={false}
                                          autoComplete={true}
                                          style={
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: "rgba(155, 155, 155, 1)",
                                              borderStyle: "dotted",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              height: "30px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></TextInput>
                                      </Form.Item>

                                      <Form.Item className="kFormItem" name="istakipkart_608425_value">
                                        <TextInput
                                          id="608425"
                                          kuikaRef={this.istakipkart_608425_value_kuikaTextInputRef}
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            608425,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          maxLength={50}
                                          allowClear={false}
                                          bordered={true}
                                          disabled={false}
                                          type="text"
                                          iconColor="rgba(0, 0, 0, 1)"
                                          formatter="None"
                                          isCharOnly={false}
                                          autoComplete={true}
                                          style={
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: "rgba(155, 155, 155, 1)",
                                              borderStyle: "dotted",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              height: "30px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></TextInput>
                                      </Form.Item>

                                      <Form.Item className="kFormItem" name="istakipkart_93784_value">
                                        <TextInput
                                          id="93784"
                                          kuikaRef={this.istakipkart_93784_value_kuikaTextInputRef}
                                          placeholder={ReactSystemFunctions.translate(
                                            this.ml,
                                            93784,
                                            "placeholder",
                                            this.defaultML
                                          )}
                                          maxLength={50}
                                          allowClear={false}
                                          bordered={true}
                                          disabled={false}
                                          type="text"
                                          iconColor="rgba(0, 0, 0, 1)"
                                          formatter="None"
                                          isCharOnly={false}
                                          autoComplete={true}
                                          style={
                                            {
                                              borderBottomWidth: 1,
                                              borderColor: "rgba(155, 155, 155, 1)",
                                              borderStyle: "dotted",
                                              borderTopWidth: 0,
                                              borderRightWidth: 0,
                                              borderLeftWidth: 0,
                                              paddingTop: 8,
                                              paddingRight: 8,
                                              paddingBottom: 8,
                                              paddingLeft: 8,
                                              height: "30px",
                                              alignItems: "center",
                                              textAlign: "-webkit-left",
                                              display: "block",
                                              fontFamily: "Poppins",
                                              fontWeight: 500,
                                              fontSize: "14px",
                                              color: "rgba(68, 68, 68, 1)",
                                              letterSpacing: "0.5px"
                                            } as any
                                          }
                                        ></TextInput>
                                      </Form.Item>
                                    </VerticalStack>
                                  </KCol>

                                  <KCol
                                    id="586706"
                                    xs={2}
                                    sm={2}
                                    md={2}
                                    lg={2}
                                    xl={2}
                                    xxl={2}
                                    order={0}
                                    pull={0}
                                    push={0}
                                    style={
                                      {
                                        borderStyle: "solid",
                                        borderTopWidth: 0,
                                        borderRightWidth: 0,
                                        borderBottomWidth: 0,
                                        borderLeftWidth: 0,
                                        paddingTop: 8,
                                        paddingRight: 8,
                                        paddingBottom: 2,
                                        paddingLeft: 8,
                                        alignItems: "flex-end",
                                        textAlign: "-webkit-right",
                                        fontFamily: "Poppins",
                                        fontWeight: 500,
                                        fontSize: "14px",
                                        color: "rgba(68, 68, 68, 1)",
                                        letterSpacing: "0.5px"
                                      } as any
                                    }
                                  >
                                    <Icon
                                      id="972677"
                                      onClick={(e?: any) => {
                                        if (e && e.stopPropagation) e.stopPropagation();
                                        this.IsTakipKartComponent_972677_onClick();
                                      }}
                                      showCursorPointer
                                      visibility={this.state.isComp972677Visible}
                                      iconName="autorenew"
                                      hoverText="Vade Gün Güncelle"
                                      style={
                                        {
                                          borderStyle: "solid",
                                          borderTopWidth: 0,
                                          borderRightWidth: 0,
                                          borderBottomWidth: 0,
                                          borderLeftWidth: 0,
                                          color: "rgba(172, 38, 42, 1)"
                                        } as any
                                      }
                                    ></Icon>
                                  </KCol>
                                </KRow>
                              </KCol>
                            </KRow>

                            <KRow
                              id="234474"
                              visibility={this.state.isComp234474Visible}
                              align="top"
                              justify="start"
                              horizontalGutter={0}
                              verticalGutter={0}
                              style={
                                {
                                  borderStyle: "solid",
                                  borderTopWidth: 0,
                                  borderRightWidth: 0,
                                  borderBottomWidth: 0,
                                  borderLeftWidth: 0,
                                  backgroundRepeat: "no-repeat",
                                  backgroundSize: "contain",
                                  backgroundPosition: "left",
                                  paddingTop: 48,
                                  fontWeight: 500,
                                  fontSize: "14px",
                                  letterSpacing: "0.5px"
                                } as any
                              }
                            >
                              <KCol
                                id="209792"
                                xs={24}
                                sm={24}
                                md={24}
                                lg={24}
                                xl={24}
                                xxl={24}
                                order={0}
                                pull={0}
                                push={0}
                                style={
                                  {
                                    borderStyle: "solid",
                                    borderTopWidth: 0,
                                    borderRightWidth: 0,
                                    borderBottomWidth: 0,
                                    borderLeftWidth: 0,
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "contain",
                                    backgroundPosition: "left",
                                    fontWeight: 500,
                                    fontSize: "14px",
                                    letterSpacing: "0.5px"
                                  } as any
                                }
                              >
                                <Button
                                  id="969763"
                                  onClick={(e?: any) => {
                                    if (e && e.stopPropagation) e.stopPropagation();
                                    this.IsTakipKartComponent_969763_onClick();
                                  }}
                                  showCursorPointer
                                  label={ReactSystemFunctions.translate(this.ml, 969763, "label", this.defaultML)}
                                  size="middle"
                                  loading={false}
                                  ghost={false}
                                  block={false}
                                  htmlType="button"
                                  iconPosition="right"
                                  danger={false}
                                  style={
                                    {
                                      borderTopLeftRadius: 32,
                                      borderTopRightRadius: 32,
                                      borderBottomRightRadius: 32,
                                      borderBottomLeftRadius: 32,
                                      borderTopWidth: 1,
                                      borderRightWidth: 1,
                                      borderBottomWidth: 1,
                                      borderLeftWidth: 1,
                                      borderColor: "rgba(172, 38, 42, 1)",
                                      borderStyle: "solid",
                                      backgroundColor: "rgba(172, 38, 42, 1)",
                                      backgroundRepeat: "no-repeat",
                                      backgroundSize: "contain",
                                      backgroundPosition: "left",
                                      paddingTop: 8,
                                      paddingBottom: 8,
                                      display: "block",
                                      fontFamily: "Poppins",
                                      fontWeight: 500,
                                      fontSize: "16px",
                                      color: "rgba(255, 255, 255, 1)",
                                      letterSpacing: "0.5px"
                                    } as any
                                  }
                                ></Button>
                              </KCol>
                            </KRow>
                          </KCol>
                        </KRow>
                      </KCol>
                    </KRow>
                  </KCol>
                </KRow>

                <KDrawer
                  id="568048"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible568048: false }, () => {
                      this.callCallbackFunction(568048);
                    });
                  }}
                  visible={this.state.NavVisible568048}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible568048 && (
                      <AksiyonKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible568048: false }, () => {
                            this.callCallbackFunction(568048);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="678135"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible678135: false }, () => {
                      this.callCallbackFunction(678135);
                    });
                  }}
                  visible={this.state.NavVisible678135}
                  width="380"
                  push={true}
                  children={
                    this.state.NavVisible678135 && (
                      <FiyatlandirmaOzelKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible678135: false }, () => {
                            this.callCallbackFunction(678135);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="621069"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible621069: false }, () => {
                      this.callCallbackFunction(621069);
                    });
                  }}
                  visible={this.state.NavVisible621069}
                  width="680px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible621069 && (
                      <BFormuBankaV2_Screen
                        onClose={() => {
                          this.setState({ NavVisible621069: false }, () => {
                            this.callCallbackFunction(621069);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="769334"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible769334: false }, () => {
                      this.callCallbackFunction(769334);
                    });
                  }}
                  visible={this.state.NavVisible769334}
                  width="680px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible769334 && (
                      <BFormuTicari_Screen
                        onClose={() => {
                          this.setState({ NavVisible769334: false }, () => {
                            this.callCallbackFunction(769334);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="276007"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible276007: false }, () => {
                      this.callCallbackFunction(276007);
                    });
                  }}
                  visible={this.state.NavVisible276007}
                  width="480"
                  push={true}
                  children={
                    this.state.NavVisible276007 && (
                      <AksiyonKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible276007: false }, () => {
                            this.callCallbackFunction(276007);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="130718"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible130718: false }, () => {
                      this.callCallbackFunction(130718);
                    });
                  }}
                  visible={this.state.NavVisible130718}
                  width="880px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible130718 && (
                      <BFormuTicariPrint_Screen
                        onClose={() => {
                          this.setState({ NavVisible130718: false }, () => {
                            this.callCallbackFunction(130718);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="733845"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible733845: false }, () => {
                      this.callCallbackFunction(733845);
                    });
                  }}
                  visible={this.state.NavVisible733845}
                  push={true}
                  children={
                    this.state.NavVisible733845 && (
                      <LeftMenu_Screen
                        onClose={() => {
                          this.setState({ NavVisible733845: false }, () => {
                            this.callCallbackFunction(733845);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={{} as any}
                ></KDrawer>

                <KDrawer
                  id="827710"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible827710: false }, () => {
                      this.callCallbackFunction(827710);
                    });
                  }}
                  visible={this.state.NavVisible827710}
                  width="680px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible827710 && (
                      <BFormuBankaV2_Screen
                        onClose={() => {
                          this.setState({ NavVisible827710: false }, () => {
                            this.callCallbackFunction(827710);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="921294"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible921294: false }, () => {
                      this.callCallbackFunction(921294);
                    });
                  }}
                  visible={this.state.NavVisible921294}
                  width="680px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible921294 && (
                      <BFormuTicari_Screen
                        onClose={() => {
                          this.setState({ NavVisible921294: false }, () => {
                            this.callCallbackFunction(921294);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="70755"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible70755: false }, () => {
                      this.callCallbackFunction(70755);
                    });
                  }}
                  visible={this.state.NavVisible70755}
                  width="680px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible70755 && (
                      <BFormuBankaV2_Screen
                        onClose={() => {
                          this.setState({ NavVisible70755: false }, () => {
                            this.callCallbackFunction(70755);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="253929"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible253929: false }, () => {
                      this.callCallbackFunction(253929);
                    });
                  }}
                  visible={this.state.NavVisible253929}
                  width="680px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible253929 && (
                      <BFormuTicari_Screen
                        onClose={() => {
                          this.setState({ NavVisible253929: false }, () => {
                            this.callCallbackFunction(253929);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="398595"
                  placement="left"
                  onClose={() => {
                    this.setState({ NavVisible398595: false }, () => {
                      this.callCallbackFunction(398595);
                    });
                  }}
                  visible={this.state.NavVisible398595}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible398595 && (
                      <PopupMuzikBankasiSecimi_Screen
                        onClose={() => {
                          this.setState({ NavVisible398595: false }, () => {
                            this.callCallbackFunction(398595);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="165788"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible165788: false }, () => {
                      this.callCallbackFunction(165788);
                    });
                  }}
                  visible={this.state.NavVisible165788}
                  width="380px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible165788 && (
                      <FiyatlandirmaOzelKart_Screen
                        onClose={() => {
                          this.setState({ NavVisible165788: false }, () => {
                            this.callCallbackFunction(165788);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="366826"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible366826: false }, () => {
                      this.callCallbackFunction(366826);
                    });
                  }}
                  visible={this.state.NavVisible366826}
                  width="680px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible366826 && (
                      <BFormuBankaV2_Screen
                        onClose={() => {
                          this.setState({ NavVisible366826: false }, () => {
                            this.callCallbackFunction(366826);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>

                <KDrawer
                  id="75984"
                  placement="right"
                  onClose={() => {
                    this.setState({ NavVisible75984: false }, () => {
                      this.callCallbackFunction(75984);
                    });
                  }}
                  visible={this.state.NavVisible75984}
                  width="680px"
                  height="100vh"
                  push={true}
                  children={
                    this.state.NavVisible75984 && (
                      <BFormuTicari_Screen
                        onClose={() => {
                          this.setState({ NavVisible75984: false }, () => {
                            this.callCallbackFunction(75984);
                          });
                        }}
                        screenInputs={window.kuika.navigationScreenInputs}
                        navigator={this.props.navigator}
                        form={this.props.form}
                        context={this.props.context}
                      />
                    )
                  }
                  style={
                    {
                      borderStyle: "solid",
                      borderTopWidth: 0,
                      borderRightWidth: 0,
                      borderBottomWidth: 0,
                      borderLeftWidth: 0,
                      paddingTop: 0,
                      paddingRight: 0,
                      paddingBottom: 0,
                      paddingLeft: 0,
                      fontFamily: "Poppins",
                      fontWeight: 500,
                      fontSize: "14px",
                      color: "rgba(68, 68, 68, 1)",
                      letterSpacing: "0.5px"
                    } as any
                  }
                ></KDrawer>
              </div>
            </div>
          </Form>
        </Spin>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    spinnerCount: state.spinnerReducer.spinnerCount,
    photoTakeVisible: state.photoTakeReducer.visible,
    isImportMappedExcelModalVisible: state.importMappedExcelModalReducer.visible
  };
};
const mapDispatchToProps = (dispatch) => ({
  showPhotoTake: () => dispatch(showPhotoTake()),
  hidePhotoTake: () => dispatch(hidePhotoTake()),
  showStripeDrawer: (data, callback, that, future) => dispatch(showStripeDrawer(data, callback, that, future)),
  hideStripeDrawer: (data, callback, that) => dispatch(hideStripeDrawer(data, callback, that)),
  showIyzicoDrawer: (data, callback, that) => dispatch(showIyzicoDrawer(data, callback, that)),
  hideIyzicoDrawer: (data, callback, that) => dispatch(hideIyzicoDrawer(data, callback, that)),
  showImportMappedExcelModal: (dsId, tableName, primaryKey, columnMapping) =>
    dispatch(showImportMappedExcelModal(dsId, tableName, primaryKey, columnMapping)),
  hideImportMappedExcelModal: () => dispatch(hideImportMappedExcelModal())
});
const tmp = withGoogleLogin(
  withContext(withScreenInput(withForm(withHistory(connect(mapStateToProps, mapDispatchToProps)(IsTakipKart_Screen)))))
);
export { tmp as IsTakipKart_Screen };
//export default tmp;
//export { tmp as IsTakipKart_Screen };
